import React, { Component} from "react";
import { withRouter } from "react-router-dom";
import Menu from '../../components/menu';
import Back from '../../assests/bg_usr.jpg';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Footer from '../../components/footer';
import { login, setGrupo, setUser, setId, setMail  } from "../../services/auth";
import  api  from "../../services/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// loads the Icon plugin
UIkit.use(Icons);

let styles = {
    main:{
    backgroundImage: 'url('+Back+')',
    minHeight: '100vh',
    backgroundRepeat: 'no-repeat' , 
    },
    card: {
        borderRadius: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.29)',       
        background: 'rgba(255,255,255,0.89)' 
     },
     labels: {
         borderRadius: '10px',
         color: 'black',
     },
     btn:{
        borderRadius: '10px',
     }

}

class LoginUser extends Component {
 state = {
     grupo:null,
     user:null,
     password:null,
     load:"Login",
     error:"",
 }
 handleLogin =  async e => {
    e.preventDefault();
    const {grupo, user, password } = this.state;
    console.log(this.state)
      // eslint-disable-next-line eqeqeq
      if(grupo == null || grupo == undefined){
        toast.error('Informe o Grupo', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, 
          });
          this.setState({
            error:
              "Houve"
          });            
      }else if(user == null){
        toast.error("Informe o Usuário",{
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, 
          });
        }else if(password == null ){
            toast.error("Informe a Senha",{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, 
              });
        }else{
            this.setState({loading: true})
            //if(this.state.loading === true){
            //this.setState({load: "Autenticando"})
            //}else{this.setState({load: "Entrar"})}
      try {
        const response = await api.post("/auth/user", { grupo, user, password })
        var erro = response.data.error
        if(erro){
            toast.error(erro,{
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, 
              });
            }else{
                setGrupo(response.data.grpid);
                login(response.data.token);
                setUser(response.data.user);
                setId(response.data.userid)
                setMail(response.data.email)
                this.props.history.push("/user");
            }
        } catch (err) {
        toast.error("Login Inválido",{
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, 
          });
        this.setState({
          error:
            "Houve um problema com o login, verifique suas credenciais."
        });            
      }
    }
     

 }
    render(){
        return(
            <div className="uk-container-fluid uk-background-cover uk-flex uk-flex-column uk-flex-1 " style={styles.main} uk-img>
            <Menu></Menu>
            <div className="" >
            {/*Login*/}
            <div className="uk-card uk-card-default uk-text-center uk-align-center uk-width-1-3@m uk-width-1-2@s uk-background-default uk-margin-large-top uk-border-rounded uk-box-shadow-large" style={styles}>
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                            <h3 className="uk-card-title uk-margin-remove-bottom uk-center">Login - Usuário</h3>
                    </div>
                </div>
                <div className="uk-card-body">
                 <form onSubmit={this.handleLogin}>
                 <div className="uk-margin">
                    <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="icon: users"></span>
                        <input className="uk-input uk-form-width-large uk-text-uppercase" maxlength="5" type="text" onChange={e => this.setState({ grupo: e.target.value.toUpperCase()})} placeholder="Grupo" style={styles.labels}/>
                    </div>
                    </div>
                    <div className="uk-margin">
                    <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="icon: user"></span>
                        <input className="uk-input uk-form-width-large" type="text" placeholder="Usuário" onChange={e => this.setState({ user: e.target.value.toUpperCase()})}  style={styles.labels}/>
                    </div>
                    </div>
                    <div className="uk-margin">
                        <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="icon: lock"></span>
                        <input className="uk-input uk-form-width-large" type="password" onChange={e => this.setState({ password: e.target.value.toUpperCase()})} placeholder="Senha" style={styles.labels} />
                    </div>
                    </div>
                    <button className="uk-button uk-button-primary uk-background-primary" type="submit"  style={styles.btn}>{this.state.load}</button>  
                </form>
                </div>
            </div>
            </div>
            <ToastContainer />
            <Footer></Footer>
            </div>
        );
    }
}
export default withRouter(LoginUser);