/* eslint-disable eqeqeq */
import React,{useRef} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
// loads the Icon plugin
UIkit.use(Icons);

export default function GridChamados ({toggle,load}) {
    const chamados = useSelector(state => state.chamados);
    const show = useRef(false)
    console.info(chamados)
    const dispatch = useDispatch()
    const iconload = load
    const modaltable = (props) => {
        show.current = true
        dispatch({type:'ADD_CHAMADO', chamado: [props.ch]})  
        toggle()
    }

  return (
    <>
    <div className="uk-text-center uk-text-small uk-flex uk-flex-center uk-flex-row uk-margin-small-top uk-padding-small">                            
            <table className="uk-table uk-table-striped uk-text-small uk-table-small uk-text-emphasis uk-background-default  uk-width-2-2@s uk-table-responsive uk-border-rounded uk-box-shadow-medium">
                <thead>
                    <tr>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Situação</th>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">N° Chamado</th>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Problema</th>
                        <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Perfil do Problema</th>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Status</th>
                        <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Data Chamado</th>  
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Tempo Chamado</th>
                        <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Operador</th>  
                        <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Cliente</th>  
                        <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Ações</th>
                    </tr>
                </thead>
    <tbody className="uk-text-center uk-text-small">
                    {chamados.map( function(ch,index){
                        var color
                        var cchf;
                    if(ch.STATUS.trim() === 'A'){
                           color = '#ffff00'
                           cchf = 'null'
                        }
                    if(ch.STATUS.trim() === 'E'){
                            color = '#ff0080'
                            cchf = 'null'
                          }
                    if(ch.STATUS.trim() === 'O'){
                            color = 'blue'
                            cchf = 'null'
                          }
                    if(ch.STATUS.trim() === 'F'){
                             color = 'green'
                             cchf = 'none'
                          }
                    if(ch.STATUS.trim() === 'T'){
                             color = '#008080'
                             cchf = 'null'
                          }
                    if(ch.STATUS.trim() === 'R'){
                             color = '#008000'
                             cchf = 'null'
                          }
                    if(ch.STATUS.trim() === 'L'){
                             color = '#e50000'
                             cchf = 'null'
                          }
                    if(ch.STATUS.trim() === 'M'){
                             color = '#000'
                             cchf = 'null'
                          }
                        return (
                            <tr className="uk-text-small" key={ch.CAMPO1}>
                              <td><span className="uk-icon-button"  style={{backgroundColor: color }}></span></td>
                              <td>{ch.CAMPO1}</td>
                              <td>{ch.TSERVICO}</td>
                              <td>{ch.TPROBLEMA}</td>
                              <td style={{display: cchf}}>{ch.STATUS_TXT}</td>
                              <td>{ch.TIME}</td>
                              <td>{ch.TEMPO_CHAMADO}</td>
                              <td>{ch.OPR}</td>
                              <td>{ch.TPCLIENT == 1 ? ch.CLIENTE : ch.FANTASIA}</td>
                              <td>
                                <button className="uk-button uk-button-text"  type="button"  onClick={() => modaltable({ch: ch})} ><i className="uk-text-primary" uk-icon="icon: plus-circle; ratio: 0.8"></i></button>                                
                              </td>
                          </tr>
                    )})}
                </tbody>
                </table>            
            </div>          
            {iconload && <div uk-spinner="ratio: 3" className="uk-flex-center uk-text-center" style={{ color: 'white' }}></div>}
                </>
  )
}