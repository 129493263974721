    /* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef} from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';
import MenuOpr from '../../components/menuop';
import Footer from '../../components/footer';
import Back from '../../assests/bg_usr.jpg';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import api from '../../services/api';
import {getOName, getDep, getOpr, getToken } from '../../services/auth'
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/pt-br';
import GridChamados from "../../components/GridChamados";
import FilterChamados from "../../components/FilterChamados";
import ModalTableOpr from "../../components/ModalTableOpr";

const adjustTime = (params) => moment(params).locale('pt-br');
UIkit.use(Icons);

let styles = {
    main:{
        backgroundImage: 'url('+Back+')',
        minWidht: '100vh',
        minHeight:'100vh',
        backgroundRepeat: 'no-repeat', 
        overflow: 'hidden',
    },
    btn:{
        margin: '10px'
    },
    txtch:{
        fontSize: '12px'
    },
    orders:{
        backgroundColor: 'rgba(0,0,0,0.4)'
    }
}
function Operador (){
    const [iconload, setIconload] = useState(true);
    const [msg, setMsg] = useState(null);
    const [mountModal, setMountModal] = useState(false);
    const oprid = getOpr();
    const order = useRef('default')
    const [status, setStatus] = useState([{'CAMPO4': 'A', 'CAMPO2': 'Aguardando Atendimento'}]);
    const chamados = useSelector(state => state.chamados)
    const stats = useSelector(state => state.stats);
    const clientes = useSelector(state => state.clientes);
    const [id_client, setId_client] = useState(null);
    const [name_client, setName_client] = useState(null);
    const [tp_client, setTp_client] = useState(2);
    const [client, setClient] = useState(null);
    const tservices = useSelector(state => state.services);
    const tproblema = useSelector(state => state.problemas);    
    const [tipo_s, setTipo_s] = useState(null);
    const [tipo_p, setTipo_p] = useState(null);
    const [button, setButton] = useState('Salvar');
    const [contato, setContato] = useState('');
    const [desc, setDesc] = useState('');
    const [active, SetActive] = useState(null);
    const [infovalue, SetInfValue] = useState(null);
    const [suggestions, setSuggestions] = useState(null)
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const showSuggestions  = useRef()
    const [userInput, setUserInput] = useState('');
    const [email,setEmail] = useState('')
    const [tch, SetTCh] = useState('S');
    const [tel, setTel] = useState('');
    const [inf, SetInf] = useState([]);
    const [grp, SetGrp] = useState(0);
    const [concluido, SetConcluido] = useState(true);
    const [causador, setCausador] = useState();
    const [maile, SetMailE] = useState(''); 
    const mails = useSelector(state => state.emails)
    const [idch, setIdCh] = useState();
    const [contat, SetContat] = useState('');
    const [problem, SetProblem] = useState('');
    const [telefone, SetTelefone] = useState('');
    const [endereco, SetEndereco] = useState('');
    const [exibir, SetExibir] = useState(false);
    const [npred, SetNpred] = useState('');
    const [bairro, SetBairro] = useState('');
    const [uf, SetUf] = useState('');
    const [cpo6, setCPO6] = useState();
    const [cpo10, setCPO10] = useState();
    const [cpo11, setCPO11] = useState();
    const [save, setSave ] = useState(false);
    const [obs, SetObs] = useState('');
    const [cidade, SetCidade] = useState('');
    const [nfantasia, SetNfantasia] = useState('');
    const [suggestionsListComponent, setSuggestionsListComponent] = useState();
    const [t31cpo1, SetT31CPO1] =  useState('');
    const operador_atual = useRef();
    const [partner,SetPartner] = useState([]);
    const dispatch = useDispatch();     
    

    useEffect(() => {
    async function populate(){
        order.current = 'default'
        const select = document.querySelector('#filter')
        api.defaults.headers.common = {'Authorization': `Bearer ${getToken()}`}
        try{
            //status
            const status = await api.get('/tp_status')
            dispatch({type: 'ADD_STATS', stats: [...status.data] })
            //clientes
            const clientes = await api.get('/clientes')
                dispatch({ type: 'ADD_CLIENTES', clientes: [...clientes.data]})
                setClient(clientes.data.filter(client => client['TP'] == '2' ? client : null))
            //chamados
                const emails = await api.get('/opr/emails')
                if (emails.data.length > 0){
                    dispatch({type: 'ADD_EMAILS', emails : [...emails.data]})
                }
            //chamados e operadores    
            const chamados = await api.get(`/opr/stch/0`)
                if(chamados.data.length > 0){
                const oprs = await chamados.data.map(opr => opr.OPR).filter((value, index, self) => { 
                         return self.indexOf(value) === index })
                dispatch({type: 'ADD_OPERADORES', operadores: [...oprs.sort()]})
                const byopr = chamados.data.filter((value) => { return (value.OPR === getOName())})
                if(byopr.length > 0){
                    const abertos = chamados.data.filter(value => value.STATUS.trim() === "A" && value.OPR !== getOName()) 
                        dispatch({type: 'ADD_CHAMADOS', chamados: [...abertos,...byopr,]})
                        setIconload(false)
                        
                        const indexOpr = select.innerText.split('\n').indexOf(getOName())
                        select.selectedIndex = indexOpr
                }else{
                    dispatch({type: 'ADD_CHAMADOS', chamados: [ ...chamados.data]})
                    setIconload(false)
                    select.selectedIndex = 0
                }

                }                    
            
                //tipo de serviços
            const servicos = await api.get('/tp_s')
            dispatch({type:'ADD_SERVICES', services : [ ...servicos.data] })
            
            //tipo de problemas
            const problemas = await api.get('/tp_p')
            dispatch({type: 'ADD_PROBLEMAS', problemas : [ ...problemas.data]})
            
        }catch(err){ 
        toast.error("Erro ao acessar API...")
          console.log(err)
          setIconload(false)
          return err;
        }        
        
    }
     populate();  
     // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

    function toggleModal(){
            setMountModal(true);
            UIkit.modal("#consultar").show()
            
        }
    function OrderDefault(a,b){
        const compareID = (id1,id2) =>{
            if(id1 > id2) return -1
             if(id1 < id2) return 1
             if(id1 === id2) return 0
        }
             if(a.FANTASIA == "null" || a.FANTASIA === null){                    
                return a.CLIENTE.toLowerCase().localeCompare(b.FANTASIA.toLowerCase() || compareID(a.CAMPO1,b.CAMPO1))  
            }else if(b.FANTASIA == "null" || b.FANTASIA === null){
                return a.FANTASIA.toLowerCase().localeCompare(b.CLIENTE.toLowerCase() || compareID(a.CAMPO1,b.CAMPO1))
            }else if(a.FANTASIA && b.FANTASIA){
                return a.FANTASIA.toLowerCase().localeCompare(b.FANTASIA.toLowerCase() || compareID(a.CAMPO1,b.CAMPO1));
            }
    }
    function OrderChamados(value){        
        order.current = value
        switch(value) {
        case "CLIENTE" :
            const newOrder = chamados.sort((a,b) => {
                return OrderDefault(a,b)
            })
             
            dispatch({type: 'ADD_CHAMADOS', chamados: [...newOrder]})
            break;

            case "TIME" : 
                //eslint-disable-next-line
                const timeOrder = chamados.sort(function (a,b) {
                let dateA = moment(a.TIME, "DD/MM/YYYY").format('YYYY-MM-DD')
                let dateB = moment(b.TIME, "DD/MM/YYYY").format('YYYY-MM-DD')
                const dateIsSame = moment(dateA).isSame(dateB);
                const dateIsBefore = moment(dateA).isBefore(dateB,'day');
                const isAfter = moment(dateA).isAfter(dateB,'day')
                    if(isAfter) return 1 
                    if(dateIsSame) return 0 || OrderDefault(a,b)
                    if(dateIsBefore ) return -1 
                    })
            dispatch({type: 'ADD_CHAMADOS', chamados: [...timeOrder]})
            break;

          case "ATUALIZACAO" :
              //eslint-disable-next-line
             const dateOrder = chamados.sort(function (a,b) {
                /*let dateA = moment(a.ATUALIZACAO).format("DD/MM/YYYY HH:MM:ss")
                let dateB = moment(b.ATUALIZACAO).format("DD/MM/YYYY HH:MM:ss")
                const dateIsSame = moment(dateA).isSame(dateB);
                const dateIsBefore = moment(dateA).isBefore(dateB);
                const isAfter = moment(dateA).isAfter(dateB)
                    if(isAfter) return 1
                    if(dateIsSame) return 0 
                    if(dateIsBefore ) return -1 */
                let dateA = new Date(a.ATUALIZACAO);
                let dateB = new Date(b.ATUALIZACAO);
                    return dateA-dateB
                })

              dispatch({type: 'ADD_CHAMADOS', chamados: [...dateOrder]})
              break;

          case "CAMPO1" :
            //eslint-disable-next-line
             const idOrder = chamados.sort(function (a,b) {
                 if(a.CAMPO1 > b.CAMPO1) return -1 
                 if(a.CAMPO1 < b.CAMPO1) return 1 
                 if(a.CAMPO1 === b.CAMPO1) return 0 || OrderDefault(a,b)
             })
            dispatch({type: 'ADD_CHAMADOS', chamados: [...idOrder]})
            break;
            
           case "STATUS" :
            //eslint-disable-next-line
            const statusOrder = chamados.sort(function (a,b) {
                if(a.ORDERSTATUS > b.ORDERSTATUS) return 1 
                if(a.ORDERSTATUS < b.ORDERSTATUS) return -1 
                if(a.ORDERSTATUS === b.ORDERSTATUS) return 0 ||  OrderDefault(a,b)
            })
            dispatch({type: 'ADD_CHAMADOS', chamados: [...statusOrder]})
            break;

           case "OPERADOR" :
                const operatorOrder = chamados.sort(function (a,b) {
                    return a.OPR.toLowerCase().localeCompare(b.OPR.toLowerCase()) ||  OrderDefault(a,b)
                })
            dispatch({type: 'ADD_CHAMADOS', chamados: [...operatorOrder]})
            break;

          case "PROBLEMA" :
                const problemOrder = chamados.sort(function (a,b) {
                return a.TSERVICO.toLowerCase().localeCompare(b.TSERVICO.toLowerCase()) ||  OrderDefault(a,b)
            })
            dispatch({type: 'ADD_CHAMADOS', chamados: [...problemOrder]})
            break

           case "TPROBLEMA" :
                const typeOrder = chamados.sort(function (a,b) {
                return a.TPROBLEMA.toLowerCase().localeCompare(b.TPROBLEMA.toLowerCase()) ||  OrderDefault(a,b)
                })
            dispatch({type: 'ADD_CHAMADOS', chamados: [...typeOrder]})
            break

            default : 
                moment().locale('pt-br')
            //eslint-disable-next-line
            const defaultOrder = chamados.sort(function (a,b) {
                let dateA = moment(a.TIME, "DD/MM/YYYY").format('YYYY-MM-DD')
                let dateB = moment(b.TIME, "DD/MM/YYYY").format('YYYY-MM-DD')
                const dateIsBefore = moment(dateA).isBefore(dateB,'day');
                const isAfter = moment(dateA).isAfter(dateB,'day');
                const dateIsSame = moment(dateA).isSame(dateB,'day');
                if(a.ORDERSTATUS > b.ORDERSTATUS) return 1;
                if(a.ORDERSTATUS < b.ORDERSTATUS) return -1;
                if(a.ORDERSTATUS === b.ORDERSTATUS){
                        if(isAfter) return 1
                        if(dateIsBefore) return -1
                        console.log(dateIsSame+' '+dateA+' === '+dateB)
                        if(dateIsSame) {
                            const hour1 = new Date(`${dateA} ${a.DATE}`);
                            const hour2 = new Date(`${dateB} ${b.DATE}`);
                            if (hour1.getTime() > hour2.getTime()) return 1 
                            if (hour1.getTime() < hour2.getTime()) return -1 
                            if(hour1.getTime() === hour2.getTime()){
                                if(a.CODSERVICO > b.CODSERVICO) return 1 
                                if(a.CODSERVICO < b.CODSERVICO) return -1
                            }
                    } 
                }
            })
                dispatch({type: 'ADD_CHAMADOS', chamados: [...defaultOrder]})
                break
        }
    }

	 async function refresh(){
        const select = document.querySelector('#filter')
        order.current = 'default'
        try{
            setIconload(true)		
            const chamados = await api.get(`/opr/stch/${getDep()}`)
            if(chamados.data.length > 0){
            const oprs = await chamados.data.map(opr => opr.OPR).filter((value, index, self) => { 
                    return self.indexOf(value) === index })
                dispatch({type: 'ADD_OPERADORES', operadores: [...oprs.sort()]})
                const byopr = chamados.data.filter((value) => value.OPR === select.value)
                    if(byopr.length > 0){
                        const abertos = chamados.data.filter((value) => ( value.STATUS.trim() === 'A' && value.OPR !== select.value) )
                        if(abertos.length > 0){
                         dispatch({type: 'ADD_CHAMADOS', chamados: [ ...abertos,...byopr]})   
                         setIconload(false)
                        }else{
                            dispatch({type: 'ADD_CHAMADOS', chamados: [ ...byopr]})   
                            setIconload(false)
                        }
                    }else{
                            dispatch({type: 'ADD_CHAMADOS', chamados: [ ...chamados.data]})
                            setIconload(false)
                            select.selectedIndex = 0
                    }
            
            }

    }catch(err){
        setIconload(false)
        toast.error(`Erro ao atualizar chamados`)
        console.log("erro ao atualizar chamados "+err)
    }	
}
    async function onClick(e) {
        setId_client(e.currentTarget.value);
        setActiveSuggestion(0);
        setFilteredSuggestions([]);
        SetPartner([]);
        showSuggestions.current = false
        setUserInput(e.currentTarget.innerText);
        setSuggestions(false);
        // eslint-disable-next-line array-callback-return
        client.filter(cli => { if(cli['ID'] == e.currentTarget.value) { SetGrp(cli['GRP']); SetT31CPO1(cli['T31CPO1']) }else{  } })
        //Carrega Atendido Por - Atendido Para 
        const getPartner = await api.post("/opr/partner", {cliente: e.currentTarget.value, tipo: tp_client})
        SetPartner(getPartner.data[0])
    };
    
    function searchClients(e) {
        const filterClients = client.filter(
            // eslint-disable-next-line array-callback-return
            suggestion => {
                    if(suggestion['NAME']){
                        if(suggestion['NAME'].toLowerCase().indexOf(userInput.toLowerCase()) > -1)
                            return suggestion['NAME']
                    }
            })
        showSuggestions.current = true;
        if (showSuggestions && userInput) {
            if (filterClients.length) {
                setSuggestionsListComponent(
                    <ul className="suggestions">
                        {filterClients.map((suggestion, index) => {
                            return (
                                <li key={index} grp={suggestion['GRP']} onClick={(e) => onClick(e)} value={suggestion['ID']} >
                                    {suggestion['NAME']}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                setSuggestionsListComponent(
                    <div className="no-suggestions">
                        <em>Nenhum cliente encontrado!</em>
                    </div>
                );
            }
    }
    };
   async function modal(){
    try {    
       const id = await api.get('/id_ch');
        console.log(id.data)
        setIdCh(id.data.id)
        setId_client(''); 
        setName_client('');
        setTipo_p(''); 
        SetEndereco('');
        setTel('');
        SetBairro('');
        setEmail('');
        SetNpred('');
        SetNfantasia('')
        SetUf('');
        SetCidade('');
        SetNfantasia('');
        setUserInput('')
        SetInf([]);
        setContato(''); 
        setDesc('');
        SetObs('');
        setDesc('');
        setCPO6('');
        setCPO10('');
        setCPO11('');
        setTipo_s('');
        SetTelefone('');
        setUserInput('')
        SetInfValue('');
        SetContat('');
        setDesc('');
        SetExibir(false)
        UIkit.modal("#include").show();
        UIkit.switcher(document.getElementById('id_open newch')).show(0)
        if(document.getElementById('id_open newch').getElementsByClassName('uk-active') !== undefined){
            if(document.getElementById('id_open newch').getElementsByClassName('uk-active').length > 0){
                console.warn(document.getElementById('id_open newch').getElementsByClassName('uk-active')[0].innerText)
                if(document.getElementById('id_open newch').getElementsByClassName('uk-active')[0].innerText.toLowerCase() !== 'cliente'){                
                    document.getElementById('id_open newch').getElementsByClassName('uk-active')[0].className = ''
                    document.getElementById('id_open newch').getElementsByClassName('index')[0].className = 'index uk-active'
                }
            }
        }
    }catch(er){
        console.error(er)
        setIdCh('ERR_IDCHD')
        setEmail('');
        setId_client(''); 
        setName_client('');
        setTipo_p(''); 
        SetEndereco('');
        setTel('');
        SetBairro('');
        SetNpred('');
        SetNfantasia('')
        SetUf('');
        SetCidade('');
        SetNfantasia('');
        setUserInput('')
        SetInf([]);
        setContato(''); 
        setDesc('');
        SetObs('');
        setDesc('');
        setCPO6('');
        setCPO10('');
        setCPO11('');
        setTipo_s('');
        SetTelefone('');
        setUserInput('')
        SetInfValue('');
        SetContat('');
        setDesc('');
        SetExibir(false)
        UIkit.switcher(document.getElementById('id_open newch')).show(0)
        if(document.getElementById('id_open newch').getElementsByClassName('uk-active')){
            if(document.getElementById('id_open newch').getElementsByClassName('uk-active').length > 0){
                console.warn(document.getElementById('id_open newch').getElementsByClassName('uk-active')[0].innerText.toLowerCase())
                if(document.getElementById('id_open newch').getElementsByClassName('uk-active')[0].innerText.toLowerCase() !== 'cliente'){                
                    document.getElementById('id_open newch').getElementsByClassName('uk-active')[0].className = ''
                    document.getElementById('id_open newch').getElementsByClassName('index')[0].className = 'index uk-active'
                }
            }
        }
        UIkit.modal("#include").hide()
        toast.error('Erro ao Gerar Id do Chamado')
    }
}
    async function test(){
        if(id_client){
        await api.post('/inf_tec', { grp: grp, id: id_client, t31cpo1, name: userInput, tp_client}).then(data => {
            SetInf(!data.data[0] ? [] : [...data.data[0].info]);
            SetEndereco(data.data[1].empresa['CPO6']);
            SetTelefone(tp_client == 1 ? data.data[1].empresa['CPO25'] : data.data[1].empresa['CPO24'])
            SetNpred(data.data[1].empresa['CPO7']);
            setName_client(data.data[1].empresa['CPO5'])
            SetNfantasia(data.data[1].empresa['CPO46']);
            SetUf(data.data[1].empresa['T14CPO2']);
            SetCidade(data.data[1].empresa['T15CPO3']);
            SetBairro(data.data[1].empresa['T16CPO3']);
            setContato(data.data[1].empresa['T182CPO25'])
            setTel(data.data[1].empresa['T182CPO27'])
            setEmail(data.data[1].empresa['T182CPO26'])
            setCPO10(data.data[1].empresa['CPO10'])
            setCPO11(data.data[1].empresa['CPO11'])
        }).catch(error => {
            console.log(error)
            toast.error('Não foi possivel buscar dados do cliente');
            SetInf([]);
            SetEndereco('');
            SetTelefone('')
            SetNpred('');
            setName_client('')
            SetNfantasia('');
            SetUf('');
            SetCidade('');
            SetBairro('');
            setContato('')
            setTel('')
            setEmail('')
            setCPO10('')
            setCPO11('')
        })
        UIkit.modal("#include").show();
    }else{
        UIkit.modal("#include").show();
    }
}

    function modalcliente(){
        SetTelefone('')
        UIkit.modal("#cliente").show();
        showSuggestions.current = false;
        setUserInput('');
        SetInf([]);
        SetInfValue('');
        SetActive('')
    }
    async function handleClients (e) {
        showSuggestions.current = false;
        setUserInput('');
        setTp_client(e.target.value)
        setClient(clientes.filter(client => client['TP'] == `${e.target.value}` ? client : null))
    }
    
    function CloseModal(){
           setDesc(' ');
            setId_client(''); 
            setName_client('');
            setTipo_p(''); 
            SetEndereco('');
            setTel('');
            SetBairro('');            
            SetNpred('');
            SetNfantasia('')
            SetUf('');
            setEmail('');
            SetCidade('');
            SetNfantasia('');
            setUserInput('')
            SetInf([]);
            setContato('');             
            SetObs('');
            setDesc('');
            setCPO11('');
            setCPO10('');
            setTipo_s('');
            SetTelefone('');
            setUserInput('')
            SetInfValue('');
            UIkit.modal('#include').hide()
    }

    async function handleSave (e) {
        e.preventDefault();
        let date = adjustTime().format("YYYY-MM-DD")
        let opr = getOName();
                if(tp_client === null){
                      toast.error("Tipo de Cliente",{containerId: 'SaveChamado'});
                } else if(name_client === null || !name_client.trim()){
                      toast.error("Informe o Cliente", {containerId: 'SaveChamado'})
                }else if(contato === null ||!contato.trim()){
                      toast.error("Informe o Contato", {containerId: 'SaveChamado'})
                }else if(email === null || !email.trim()){
                      toast.error("Informe o Email do Contato", {containerId: 'SaveChamado'})
                }else if(tel === null || !tel.trim()){
                      toast.error("Informe o Telefone de Contato", {containerId: 'SaveChamado'})
                }else if(tipo_s === null || !tipo_s.trim()){
                      toast.error("Informe o tipo de Serviço", {containerId: 'SaveChamado'})
                }else if(tipo_p == null || !tipo_p.trim()){
                      toast.error("Informe o tipo de problema",{containerId: 'SaveChamado'});
                }else if(contato == null || !contato.trim()){
                      toast.error("Informe o contato",{containerId: 'SaveChamado'}); 
                }else if(desc == null || !desc.trim()){
                      toast.error("Informe a descrição do problema",{containerId: 'SaveChamado'}); 
                }else{
                    setButton(<div uk-spinner="ratio: 1"></div>)
                    setSave(true);
                    setIconload(true)
                    
                    try{
                       const save = await api.post('/opr/ch', { npred, idch, id_client, nfantasia , name_client, date, endereco, tipo_s, tipo_p, contato, desc, telefone, bairro, tch, tp_client, opr, email, tel, obs, contat, problem, exibir, oprid, cpo10, cpo11 })
                        toast.success(save.data.ch);
                        toast.success(save.data.mail);
                            setId_client(null); 
                            setName_client(null);                        
                            SetEndereco(null);
                            setTel(null);
                            SetBairro(null);
                            setIdCh(null)
                            SetNpred(null);
                            SetUf(null);
                            SetCidade(null);
                            SetNfantasia(null);
                            setUserInput(null)
                            SetObs(null);
                            SetInf([]);
                            setContato('');
                            setEmail(''); 
                            setDesc(null);
                            setButton('Salvar');
                            SetObs(null);
                            setTipo_p(null)
                            setTipo_s(null);
                            SetTelefone(null);
                            setUserInput(null)
                            setSave(false)
                            setEmail('')
                            UIkit.modal("#include").hide();
            
            const chamados = await api.get(`/opr/stch/0/`)
            SetProblem('');
            const byopr = chamados.data.filter((value) => { return (value.OPR === getOName() || value.STATUS.trim() === 'A') })
            const oprs = await chamados.data.map(opr => opr.OPR).filter((value, index, self) => { 
                return self.indexOf(value) === index })
            dispatch({type: 'ADD_OPERADORES', operadores: [...oprs.sort()]})
             var select = document.querySelector('#filter')
             select.value =  getOName()
             dispatch({type: 'ADD_CHAMADOS', chamados: [ ...byopr]})
             setIconload(false)
             order.current = 'default'
        }catch(error){           
                console.log(error)            
                toast.error("Erro ao abrir chamado! Tente novamente")    
                UIkit.modal("#include").hide();
                setIconload(false)
                setButton('Salvar')
                setSave(false);
                setButton('Salvar')
                setSave(false);
                setId_client(null); 
                setName_client(null);                        
                SetEndereco(null);
                setTel(null);
                SetBairro(null);
                SetProblem('');
                setIdCh(null)
                SetNpred(null);
                SetUf(null);
                setEmail('');
                SetCidade(null);
                SetNfantasia(null);
                setUserInput(null)
                SetObs(null);
                SetInf([]);
                setContato(''); 
                setDesc(null);
                setButton('Salvar');
                SetObs(null);
                setTipo_p(null)
                setTipo_s(null);
                SetTelefone(null);
                setUserInput(null)
                setSave(false)
                setEmail('')
                
            }
            }
                                     
        }        
        return(
            <div  className="uk-container-fluid uk-background-cover uk-background-fixed uk-flex  uk-flex-column uk-flex-1 " style={styles.main} uk-img="true">
            <MenuOpr></MenuOpr>                       
            <ToastContainer enableMultiContainer containerId={'SaveChamado'}  />
            <ToastContainer />
            <div className="uk-container-fluid">            
            <div className="uk-background-default uk-text-center uk-width-1 uk-margin uk-flex uk-flex-center uk-flex-row uk-border-rounded uk-box-shadow-medium uk-margin-top uk-padding-small" style={{width: '98%', height:'auto', marginRight:'auto', marginLeft:'auto'}}>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#ffff00', width:'25px', height:'25px'}}> </span> Aguardando Atendimento </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#ff0080', width:'25px', height:'25px'}}> </span> Em Atendimento </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: 'blue', width:'25px', height:'25px'}}> </span> Ordem de Serviço </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#008080', width:'25px', height:'25px'}}> </span> Atendimento TSP </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#008000', width:'25px', height:'25px'}}> </span> Aguardando Feedback do Cliente </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#e50000', width:'25px', height:'25px'}}> </span> Falta de Energia </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#000', width:'25px', height:'25px'}}> </span> Em Estudo </div>
           </div>
           <div className="uk-container-fluid uk-flex uk-flex-left justify-content-center align-items-end">           
            <FilterChamados load={setIconload} order={order}/>
            <div>
		       <button className="uk-button btn-info uk-border-rounded uk-box-shadow-xlarge uk-margin-right uk-margin-left uk-text-center-left" type="button" onClick={() => refresh()}>{iconload && <div uk-spinner="ratio: 1" className="uk-flex-center uk-text-center" style={{ color: 'white' }}></div>}<span uk-icon='icon:  refresh'></span> Atualizar Chamados</button>
               <button className="uk-button btn-success uk-border-rounded uk-box-shadow-xlarge  uk-text-center-left" type="button" onClick={(e) => modal()}><span uk-icon='icon: plus'></span> Incluir Chamado</button>
            </div>
            <div className=" uk-margin-medium-left">
            <label className="uk-form-label text-light">Ordenar Chamados</label>
            <div className="uk-form-controls">
                <select className="uk-select" name="order" onChange={(e) => OrderChamados(e.currentTarget.value)} value={order.current}>
                    <option value="default">Padrão</option>
                    <option value="CAMPO1">N° Chamado</option>
                    <option value="STATUS">Status</option>
                    <option value="TIME">Data do Chamado</option>
                    <option value="ATUALIZACAO">Tempo do Chamado</option>
                    <option value="CLIENTE">Cliente</option>
                    <option value="PROBLEMA">Problema</option>
                    <option value="TPROBLEMA">Perfil do Problema</option>
                    <option value="OPERADOR">Operador</option>
                </select>
            </div>
            </div>
           </div>
           <div className="uk-container-fluid uk-flex-center uk-text-center">
            <GridChamados toggle={toggleModal} load={iconload} />
               
            <div className="uk-flex-right uk-flex uk-margin-right ">
                <div id="include" uk-modal="true" style={{display:'none'}} bg-close="false">
                <div className="uk-modal-dialog uk-modal-body uk-width-1-1 uk-height-auto uk-border-rounded">
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">Incluir Chamado</h2>
                        <div className="uk-width-expand@s uk-flex uk-flex-row uk-flex-end">
                            <div className="uk-width-1-1 uk-flex-row uk-flex-1 uk-flex">
                                <div className="uk-text-left uk-margin uk-flex-left">
                                    <div className="uk-text-left">
                                        <p><span className="uk-padding-small"><span className="uk-text-bold">N° Chamado: </span> {idch} </span>
                                           <span className="uk-padding-small"><span className="uk-text-bold ">Status: </span> Aguardando Atendimento </span>
                                           <span className="uk-padding-small"><span className="uk-text-bold ">Incluído por: </span> {getOName()} </span>
                                           <span className="uk-padding-small"><span className="uk-text-bold">Operador: </span> {getOName()}</span>
                                        </p>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                      <div className="">
                        <div className="">
                                            <div className="uk-flex uk-flex-row">
                                            <div className="uk-width-1-1@m uk-width-1-1@s uk-width-1-4 ">
                                                <div className="uk-form-label">Tipo de Chamado</div>
                                                <div className="uk-form-controls uk-flex uk-flex-row py-1" >
                                                        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                                            <label><input  className="uk-radio" type="radio"  value="C" checked={tch == 'C' ? true : false} onChange={(e) => SetTCh("C")}/> Conserto </label>
                                                            <label><input  className="uk-radio" type="radio"  value="O" checked={tch == 'O' ? true : false} onChange={(e) => SetTCh("O")}/> Orçamento </label>
                                                            <label><input  className="uk-radio" type="radio"  value="R" checked={tch == 'R' ? true : false} onChange={(e) => SetTCh("R")}/> Reparo </label>
                                                            <label><input  className="uk-radio" type="radio"  value="I" checked={tch == 'I' ? true : false} onChange={(e) => SetTCh("I")}/> Instalação </label>
                                                            <label><input  className="uk-radio" type="radio"  value="S" checked={tch == 'S' ? true : false} onChange={(e) => SetTCh("S")}/> Suporte </label>
                                                            <label><input  className="uk-radio" type="radio"  value="D" checked={tch == 'D' ? true : false} onChange={(e) => SetTCh("D")}/> Desinstalação </label>
                                                            <label><input  className="uk-radio" type="radio"  value="M" checked={tch == 'M' ? true : false} onChange={(e) => SetTCh("M")}/> Manutenção </label>
                                                        </div>
                                                    </div>                                            
                                                </div>
                                            <div className="uk-width-1-1@m uk-width-1-1@s uk-width-1-4 ">
                                                    <div className="uk-form-label">Causador do Problema</div>
                                                    <div className="uk-form-controls py-1">
                                                        <div className="uk-flex-column uk-flex px-2">
                                                            <label><input className="uk-radio" type="radio" name="radio2" disabled={concluido} onClick={() => setCausador("C")}/> Cliente </label>
                                                            <label><input className="uk-radio" type="radio" name="radio2" disabled={concluido} onClick={() => setCausador("P")}/> Prestador </label><br />
                                                        </div>
                                                    </div>    
						<p className="uk-text-right  ">
                            <button id="cancel" style={styles.btn} className="uk-button uk-button-danger uk-border-rounded" onClick={() => CloseModal()} type="button"><span uk-icon='icon: close'></span> Cancelar</button>
                            <button id="save" style={styles.btn} disabled={save}  onClick={(e) => handleSave(e)}  className={save ? `uk-button  uk-border-rounded` : `uk-button btn-success uk-border-rounded`} type="button"><span uk-icon='icon: check'></span> {button}</button>
                        </p>													
                    </div>
            </div>
            </div>
            </div>
                                    <ul id="id_open newch" className="uk-subnav uk-subnav-pill" uk-switcher={`connect: .switcher-container-open; animation: uk-animation-fade`}>
                                        <li id={`id_open`} className="index"><a href="#1">Cliente</a></li>
                                        <li><a href="#2">Problema</a></li>
                                        <li><a href="#3">Atendimento</a></li>
                                        {//<li><a href="#4">Encaminhar</a></li>
                                        }
                                    </ul>
                                    <ul className={`uk-switcher switcher-container-open uk-margin`}>
                                        <li>
                                            <form  className="uk-form-stacked">
                    <div className="uk-flex uk-flex-row uk-flex-1 uk-width-1-1">
                            <div className="uk-width-expand@s uk-width-1-1 uk-width-1">
                              <div className="uk-flex uk-flex-wrap uk-flex-wrap-around ">
                                <div className="uk-width-1-1@s uk-width-1-1 uk-padding-small ">
                                    <label className="uk-form-label" htmlFor="form-stacked-input">Cliente</label>
                                        <div className="uk-width-1-1">
                                           <div className="uk-form-controls"> 
                                            <input className="uk-input uk-margin-right-small" style={{width: '10%'}} id="form-stacked-input" placeholder="Cód." name="id_cliente" value={id_client} required />
                                            <input className="uk-input uk-margin-right-small" style={{width: '75%'}} id="form-stacked-input "  placeholder="Nome Cliente" name="name_cliente" value={name_client} required/>
                                            <button onClick={() => modalcliente()} style={{height: '100%'}}  className="uk-button btn-info  uk-border-rounded uk-1-3" type="button"><span uk-icon='icon: search'></span></button> 
                                        </div>
                                        </div>
                                </div>
                                </div>
                                                            <div className="uk-flex uk-flex-wrap uk-flex-wrap-around">
                                                            <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                                                <label className="uk-form-label" htmlFor="form-stacked-select">Endereço</label>
                                                                <div className="uk-form-controls">
                                                                    <input className="uk-input" disabled defaultValue={endereco} />
                                                                </div>
                                                            </div>
                                                            <div className="uk-width-1-2@m uk-width-1-2@s uk-padding-small">
                                                                <label className="uk-form-label" htmlFor="form-stacked-select">Telefone</label>
                                                                <div className="uk-form-controls">
                                                                    <input className="uk-input"  disabled defaultValue={telefone} />
                                                                </div>
                                                            </div>
                                                            <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                                                <label className="uk-form-label" htmlFor="form-stacked-select">Bairro</label>
                                                                <div className="uk-form-controls">
                                                                    <input className="uk-input" disabled defaultValue={bairro} />
                                                                </div>
                                                            </div>
                                                            <div className="uk-width-1-4@m uk-width-1-3@s uk-padding-small">
                                                                <label className="uk-form-label" htmlFor="form-stacked-select">N° Prédio</label>
                                                                <div className="uk-form-controls">
                                                                    <input className="uk-input" disabled defaultValue={npred} />
                                                                </div>
                                                            </div>
                                                            <div className="uk-width-1-4@m uk-width-1-3@s uk-padding-small">
                                                                <label className="uk-form-label" htmlFor="form-stacked-select">UF</label>
                                                                <div className="uk-form-controls">
                                                                    <input className="uk-input" disabled defaultValue={uf} />
                                                                </div>
                                                            </div>
                                                            <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                                                <label className="uk-form-label" htmlFor="form-stacked-select">Cidade</label>
                                                                <div className="uk-form-controls">
                                                                    <input className="uk-input" disabled defaultValue={cidade} />
                                                                </div>
                                                            </div>

                                                            <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                                                <label className="uk-form-label" htmlFor="form-stacked-select">{tp_client == 1 ? `Nome Cliente ` : `Nome Fantasia`}</label>
                                                                <div className="uk-form-controls">
                                                                    <input className="uk-input"  disabled defaultValue={tp_client == 1 ? userInput : nfantasia} />
                                                                </div>
                                                            </div>
                            </div>
                            <div className="uk-flex  uk-flex-wrap uk-flex-wrap-around ">
                                <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                <label className="uk-form-label " htmlFor="form-stacked-contato" required>Nome do Contato</label>
                                <div className="uk-form-controls">
                                    <input className="uk-input" id="form-stacked-conato" type="text" name="contato" maxLength={50} value={contato} onChange={(e) => setContato(e.target.value)} required />
                                </div>
                            </div>
                            
                                <div className="uk-width-1-2@m uk-width-1-2@s uk-padding-small">
                                    <label className="uk-form-label " htmlFor="form-stacked-email" required>Email</label>
                                    <div className="uk-form-controls">
                                        <input className="uk-input" id="form-stacked-email" type="email" maxLength={100} name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                    </div>
                                </div>
                            </div>
                            <div className="uk-flex  uk-flex-wrap uk-flex-wrap-around ">
                                <div className="uk-width-1-2@m uk-width-1-2@s uk-padding-small">
                                    <label className="uk-form-label " htmlFor="form-stacked-fone" required>Telefone</label>
                                        <div className="uk-form-controls">
                                          <input className="uk-input" id="form-stacked-fone" maxLength={14} type="tel" name="phone" required value={tel} onChange={(e) => setTel(e.target.value)}/>
                                        </div>
                                    </div>                                                                                     
                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                           <label className="uk-form-label" htmlFor="form-stacked-text">Observação</label>
                           <div className="uk-form-controls">
                              <textarea className="uk-textarea uk-form-large" id="form-stacked-text" type="text" maxLength={250} rows="1" value={obs} onChange={(e) => SetObs(e.target.value)} ></textarea>
                           </div>  
                        </div>
                        { partner['T465CPO4'] !== 1 && partner['T465CPO4'] !== 2 ? 
                                            <span></span>
                                            :
                                            <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small">
                                            <label className="uk-form-label" htmlFor="form-stacked-select">Tipo de Atendimento</label>
                                            <div className="uk-form-controls" >
                                            { partner['T465CPO4'] == 1 ?
                                                <input className="uk-input" style={{backgroundColor:'#76d7ea', color:'#000'}} disabled 
                                                value={`Atendido por ${partner['PARCERIA']}`} />
                                                :
                                                <input className="uk-input" style={{backgroundColor:'#118C4F', color:'#000'}} disabled 
                                                value={`Atendido para ${partner['PARCERIA']}`}/>
                                            }
                                            </div>
                                            </div>
                                        }
                        </div>
                    </div>
                        <div className="uk-width-1-3 ">
                        <div className="uk-width-expand@s uk-width-1-1">
                                        <table className="uk-table uk-table-divider">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="uk-text-bold uk-table-shrink">Informações Técnicas</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {inf.map((element,index) => {
                                                                            if(!inf){
                                                                                return (
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                )

                                                                            }else {
                                                                            return (
                                                                                <tr key={index}>  
                                                                                    <td><input className="uk-radio" type="radio" name='inftec' placeholder={element['key']} checked={active === element['key'] ? true : false} onChange={() => {
                                                                                        SetInfValue(element['value']);
                                                                                        SetActive(element['key'])
                                                                                    }} /></td>
                                                                                    <td>{element['key']}</td>
                                                                                </tr>
                                                                            ) }
                                                                        })}

                                                                    </tbody>
                                                                </table>
                                                                <label className="uk-form-label" htmlFor="form-stacked-text">Observação</label>
                                                                <div className="uk-form-controls">
                                                                    <textarea className="uk-textarea uk-form-large" rows="8" disabled defaultValue={infovalue}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                    
                        
                    </form>
                    </li>
                        <ul> 
                                   <div className="uk-flex uk-flex-wrap uk-flex-wrap-around ">
                                                    <div className="uk-padding-small uk-width-1-3@m uk-width-1-1@s">
                                                        <label className="uk-form-label" htmlFor="form-stacked-select">Tipo de Serviço</label>
                                                        <div className="uk-form-controls">
                                                            <select className="uk-select" id="form-stacked-select" name="tipo_s" value={tipo_s} required onChange={(e) => setTipo_s(e.target.value)}>
                                                                <option>Selecionar Tipo de Serviço</option>
                                                                {tservices.map(service => (
                                                                    <option key={service.CAMPO1} value={service.CAMPO1}>{service.CAMPO2}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                                        <label className="uk-form-label" htmlFor="form-stacked-select">Tipo de Problema</label>
                                                        <div className="uk-form-controls">
                                                            <select className="uk-select" id="form-stacked-select" name="tipo_p" required value={tipo_p} onChange={(e) => setTipo_p(e.target.value)}>
                                                                <option >Selecionar Tipo de Problema</option>
                                                                {
                                                                    // eslint-disable-next-line array-callback-return
                                                                    tproblema.map(prob => {
                                                                        if (prob.CAMPO2 == tipo_s) {
                                                                            return <option key={prob.CAMPO1} value={prob.CAMPO1}>{prob.CAMPO3}</option>
                                                                        }
                                                                    })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                                        <label className="uk-form-label" htmlFor="form-stacked-select">Checklist do Problema</label>
                                                        <div className="uk-form-controls">
                                                            <select className="uk-select"  id="form-stacked-select" disabled >
                                                                <option value=""></option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small">
                                                        <label className="uk-form-label" htmlFor="form-stacked-text">Descrição do Problema</label>
                                                        <div className="uk-form-controls">
                                                        <textarea className="uk-textarea uk-form-large" id="form-stacked-text" maxLength={2000} required type="text" value={desc} onChange={(e) => { setDesc(e.target.value) }} ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                        </ul>
                        <ul>
                            {//Atendimento
                            }
                                            <div className="uk-flex  uk-flex-wrap uk-flex-wrap-around ">
                                                <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                                    <label className="uk-form-label" htmlFor="form-stacked-select">Status</label>
                                                    <div className="uk-form-controls">
                                                        <select className="uk-select" id="form-stacked-select"  >
                                                            {//onChange={(e) => this.setState({stat: e.target.value})}">
                                                            }
                                                            {status.map(data =>
                                                                <option key={data.CAMPO4} value={data.CAMPO4}> {data.CAMPO2} </option>
                                                           )}
                                                        </select>
                                                    </div>
                                                    <div className="uk-form-controls">
                                                        <label>
                                                            <input name="new" className="uk-checkbox" type="checkbox"
                                                            onClick={(e) => SetExibir(!exibir)} /> 
                                                            
                                                            Exibir para Cliente
                                            </label>
                                                    </div>
                                                    <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small"></div>
                                                    <label className="uk-form-label" htmlFor="form-stacked-select">Nome do Contato</label>
                                                    <div className="uk-form-controls">
                                                        <input className="uk-input"  placeholder='' maxLength={50}  value={contat} required onChange={(e) => SetContat(e.target.value)} />
                                                    </div>
                                                    <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small"></div>
                                                    <label className="uk-form-label" htmlFor="form-stacked-text">Instrução</label>
                                                    <div className="uk-form-controls">
                                                        <textarea className="uk-textarea uk-form-large" maxLength={2000} required id="form-stacked-text" type="text"  placeholder=''
                                                            value={problem} onChange={(e) => SetProblem(e.target.value)}>
                                                        </textarea>
                                                        <span className="uk-text-meta">{ !problem.trim() ? '0' : problem.length}/2000</span>
                                                    </div>
                                                </div>
                                                <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                                    <table className="uk-table uk-table-divider">
                                                        <thead>
                                                            <tr>
                                                                <th className="uk-text-bold uk-table-shrink">#</th>
                                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Data</th>
                                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Hora</th>
                                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Atendente</th>
                                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Contato</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                                    <tr >
                                                                        <td><input className="uk-radio" type="radio" placeholder={''} /></td>
                                                                        <td>{}</td>
                                                                        <td>{}</td>
                                                                        <td>{}</td>
                                                                        <td className="uk-text-truncate">{}</td>
                                                                    </tr>
                                                        </tbody>
                                                    </table>
                                                    <label className="uk-form-label" htmlFor="form-stacked-text">Instrução</label>
                                                    <div className="uk-form-controls">
                                                        <textarea className="uk-textarea uk-form-large" rows="8" disabled value={''}></textarea>
                                                    </div>
                                                </div>

                                            </div>                               
                        </ul>
                        </ul>
                    
                </div>
             </div>
               </div>
               <div id="cliente" uk-modal="true" style={{display:'none'}} bg-close="false">
                    <div className="uk-modal-dialog uk-modal-body uk-width-1-1 uk-height-1-2  uk-border-rounded">
                        <div className="uk-modal-header">
                            <h2 className="uk-modal-title">Pesquisar Cliente</h2>
                        </div>
                        <div className="uk-padding-small uk-width-1-1@m uk-width-1-1@s">
                            <label className="uk-form-label" htmlFor="form-stacked-select" required>Tipo de Cliente</label>
                                <div className="uk-form-controls">
                                    <div className="uk-margin uk-grid-small uk-child-width-auto" onClick={(e) => handleClients(e)}>
                                        <label><input className="uk-radio cliente_type" type="radio"  name="cliente_t"  value="1" defaultChecked={tp_client == 1 ? true : false}/> Físico </label>
                                        <label><input className="uk-radio cliente_type" type="radio"  name="cliente_t"  value="2" defaultChecked={tp_client == 2 ? true : false}/> Jurídico </label>
                                    </div>
                                </div>
                                <label className="uk-form-label " htmlFor="form-stacked-text" required>Nome do Cliente</label>
                                    <div className="uk-form-controls uk-flex">
                                        <input className='uk-input uk-width-1-1' onChange={(e) => setUserInput(e.currentTarget.value)} value={userInput} />
                                        <button onClick={()=> { setFilteredSuggestions([]); searchClients()}}  className="uk-button btn-info uk-button-small uk-border-rounded" type="button"><span uk-icon='icon: search'></span> </button>
                                    </div>  
                                    {showSuggestions.current && suggestionsListComponent}


                        </div>
                        <button   onClick={() => test()}  className="uk-button btn-success uk-border-rounded uk-align-right" type="button"><span uk-icon='icon: check'></span> Confirmar </button>
                        <button   onClick={() => { UIkit.modal("#cliente").hide(); UIkit.modal("#include").show(); }}  className="uk-button btn-danger uk-border-rounded uk-align-right" type="button"><span uk-icon='icon: close'></span> Voltar </button>
                    </div>
                </div>
                <div id="consultar" uk-modal className="uk-modal" bg-close="false" >
                <div className="uk-modal-dialog uk-modal-body uk-width-1-1 uk-height-auto uk-border-rounded">
                {mountModal && <ModalTableOpr order={order}/>} 
                </div>
                </div>
                <Footer></Footer>
                
            </div>
            </div>
            </div>
        );
    }
export default withRouter(Operador);

