import { createStore } from 'redux';

const data = {
    groups: [],
    clientes: [],
    services: [],
    problemas:[],
    chamados:[],
    stats:[],
    chamado:[],
    operadores:[],
    emails: []

}

function Operador( state = data, action){
    switch (action.type){
        case 'ADD_EMAILS' :
            return {
                ...state, emails: [ ...action.emails ]
            };
        case 'PUSH_EMAILS' :
            return {
                ...state.emails
            };
        case 'ADD_GRPEMP' :
            return {
                ...state, groups: [ ...action.dados ]
            };
        case 'PUSH_GRPEMP' :
            return {
                ...state.groups
            };
        case 'ADD_STATS' :
            return {
                ...state, stats: [ ...action.stats ]
            };
        case 'PUSH_STATS' :
            return {
                ...state.stats
            };
        case 'ADD_CLIENTES' :
            return{
            ...state, clientes: [...action.clientes]
        };
        case 'PUSH_CLIENTESF':
            return {
                ...state.clientes.filter( pf => pf.TP === 2 ? pf : [])
            };
        case 'PUSH_CLIENTESPJ':
            return {
                ...state.clientes.filter(pj => pj.TP === 1 ? pj : [] )
            };
        case 'ADD_CHAMADOS':
            return {
                ...state, chamados: [...action.chamados]
            };
        case 'PUSH_CHAMADOS':
            return {
                ...state.chamados
            };
            case 'ADD_CHAMADO':
            return {
                ...state, chamado: action.chamado
            };
        case 'PUSH_CHAMADO':
            return {
                ...state.chamado
            };
        case 'ADD_SERVICES':
            return {
                ...state, services: [...action.services]
            };
        case 'PUSH_SERVICES':
            return {
                ...state.services
            };
            case 'ADD_OPERADORES':
                return {
                    ...state, operadores: [...action.operadores]
                };
            case 'PUSH_OPERADORES':
                return {
                    ...state.operadores
                };
        case 'ADD_PROBLEMAS':
            return {
                ...state, problemas: [...action.problemas]
            };
        case 'PUSH_PROBLEMAS':
            return {
                ...state.problemas
            };
        default: 
        return state

    }

}

const store = createStore(Operador);

export default store;