import React from 'react';
import { logout, TOKEN_KEY, isOpr} from "../services/auth"
import Logo from '../assests/logo_tec.png';

export default class Menu extends React.Component {
  logout = () => {
    try {
        logout();
        if(localStorage.getItem(TOKEN_KEY) == null){
            this.props.history.push("/dev/loginopr");
            this.setState({erro : 'Token não destruído!!' })
    }
}catch{
    this.setState({erro : 'Não foi possivel realizar logout!' })
}
}
  render() {
    return (
      <div>
          <nav className="navbar bg-white" color="white">
          <span className="navbar-brand d-flex "><a  href="http://tscom.com.br/"><img src={Logo} className="uk-visible@m" style={{width: '80%'}}   alt='Tec System'/></a></span>
          <div className="navbar uk-flex-1 uk-flex" style={{margin:'0px auto'}} >
              <ul className="navbar-nav justify-content-center uk-text-center" style={{margin:'0px auto'}} >
                <li className="nav-item">
                <h2 className="nav-link text-body">Controle de Chamados</h2>
            </li>
        </ul>
    </div>
    <div className="uk-flex flex-1"></div>
   <div className="uk-navbar-right">
   <div className="uk-margin-small-right uk-text-primary" style={{padding:'0px'}}>
        <p><span uk-icon="icon: mail"></span> suporte@tscom.com.br</p>
        <p><span uk-icon="icon: receiver"></span> (51)3039-8305</p>
      </div>
                    <ul className="uk-navbar-nav uk-padding-small">
                    <li>
                    <button className="uk-button uk-button-link" type="button"><i uk-icon="icon: user; ratio: 1"/> <span uk-icon="icon:  triangle-down"></span></button>
                        <div uk-dropdown="pos: bottom-justify" >
                            <ul className="uk-nav uk-dropdown-nav">
                                <li className="uk-active"><a href="/admin"><span uk-icon="icon: settings; ratio: 0.8"></span> Gerenciar Usuários</a></li>
                                {isOpr() && <li className="uk-active"><a href="/operador"><span uk-icon="icon: plus; ratio: 0.8"></span> Cadastrar Usuário</a></li>}
                                <li className="uk-active"><a href="/dashopr"><span uk-icon="icon: album; ratio: 0.8"></span> Dashboard</a></li>
                                <li className="uk-nav-divider"></li>
                                <li><a className="uk-text-danger" href="/loginopr" onClick={this.logout}><span uk-icon="icon: sign-out"></span> Logout</a></li>
                            </ul>
                        </div>
                    </li>   
                    </ul>
              </div>    
              </nav>
      </div>
    );
  }
}
