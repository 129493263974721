import axios from "axios";
import { getToken } from "./auth";
const token = getToken();
const api = axios.create({
  baseURL: "https://helpdesk.tscom.com.br:3002"
  //baseURL: "http://localhost:3002"
})
api.interceptors.request.use(async config => {
  if (token) {
    config.headers.Authorization = "Bearer "+token+"" ;
  }
  return config;
});
export default api;