import React, { Component } from "react";
import UIkit from 'uikit';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from '../services/api';
moment().locale('pt-br');
export default class ModalTable extends Component{
	 /*componentDidMount = () => {
        try{
			api.get("/assent/"+this.props.id).then((ass) => {
            if(ass.data['CAMPO8'] !== null){  
    			this.setState({assentamento: [ ...ass.data ] })
                this.setState({desc: ass.data['CAMPO8'] , active : ass.data['CAMPO1'] })
            }else{
                this.setState({desc: '----', active : ass.data['CAMPO1'] })
            }
		})
		}catch(err){
			console.log(err);
		}
		
		
		
	}*/
    constructor(props){ 
        ModalTable.propTypes = {
            id: PropTypes.number,
            tipo: PropTypes.string, 
            status: PropTypes.string,
            cliente: PropTypes.string,
            tempo: PropTypes.string,
            data: PropTypes.string,
            problema: PropTypes.string,
            problemat: PropTypes.string,
			user: PropTypes.string,
          };
        super(props)
        this.state= {
			assentamento: [],
			desc: '',
            active:'',
            load: true,
           
        }
    }
     modaltable = () => {
          try{
			api.get("/assent/"+this.props.id).then((ass) => {
            if(ass.data){  
    			this.setState({assentamento: [ ...ass.data ] })
                this.setState({desc: ass.data['INSTRUCAO'] , active : ass.data['CAMPO1'], load: false })
            }else{
                this.setState({desc: '----', active : ass.data['CAMPO1'], load:false })
            }
            		})
		}catch(err){
            console.log(err);
            this.setState({load: false })
		}
      UIkit.modal("#consultar"+this.props.id).show();
      this.setState({load: false })
    }
render(){
	var date = moment(this.state.assentamento['CAMPO4']).format('L');
	//var date2 = date;
	var insert = this.props.user;
	    if(!this.state.assentamento['CAMPO1']){
        	date = moment(this.props.data).format('L');	       
		    //date2 = '';
	    }
    return(
        <div>
          <button className="uk-button uk-button-text"  onClick={() => this.modaltable()} type="button" ><i className="uk-text-primary" uk-icon="icon: plus-circle; ratio: 0.8"></i></button>
            <div id={"consultar"+this.props.id} uk-modal style={{display:'none'}} bg-close="false">
                <div className="uk-modal-dialog uk-modal-body uk-width-1-1 uk-height-auto uk-border-rounded">
                <button className="uk-modal-close-default uk-button uk-button-text " type="button" uk-close><span className="uk-text-danger" uk-icon="icon: close"></span></button>
                        <div className="uk-modal-header uk-flex uk-flex-1 uk-flex-row">
                            <h2 className="uk-modal-title uk-width-1-2@s uk-flex-left">Consulta Chamado N° {this.props.id}</h2>
                            <div className="uk-flex-1"></div>
                            <div className="uk-text-right uk-margin uk-flex-right">
                              <div className="uk-text-right">
                              <p><span className=""><span className="uk-text-bold">Incluído por:</span> { insert }</span></p>
                              <p><span className=""><span className="uk-text-bold ">Data:</span> { date } </span></p>
                            </div>
                            </div>
                            </div>
                            <form className="uk-form-stacked uk-flex-center uk-flex-row">
                                    <div className="uk-width-expand@m">
                                    <div className="uk-flex  uk-flex-wrap uk-flex-wrap-around ">
                                    <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Cliente</label>
                                         <div className="uk-form-controls">
                                            <select className="uk-select" id="form-stacked-select" disabled>                                                                                                                           
                                                   <option>{this.props.cliente}</option>                                            
                                            </select>
                                        
                                        </div>
                                        </div>
                                        <div className="uk-padding-small uk-width-1-3@m uk-width-1-1@s">
                                         <label className="uk-form-label" htmlFor="form-stacked-select">Tipo de Serviço</label>
                                         <div className="uk-form-controls">
                                            <select className="uk-select" id="form-stacked-select" disabled>
                                           
                                                   <option>{this.props.tipo}</option>                                            
                                           
                                            </select>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                         <label className="uk-form-label" htmlFor="form-stacked-select">Tipo de Problema</label>
                                         <div className="uk-form-controls">
                                            <select className="uk-select" id="form-stacked-select" disabled>                                    
                                            
                                                   <option>{this.props.problemat}</option>                                            
                                            
                                            </select>
                                        </div>
                                        </div>
                                        </div>
                                        <div className="uk-flex  uk-flex-wrap uk-flex-wrap-around ">
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Status</label>
                                         <div className="uk-form-controls">
                                            <select className="uk-select" id="form-stacked-select" disabled >
                                          
                                                   <option>{this.props.status}</option>                                            
                                          
                                            </select>
                                        
                                        </div>
                                        </div>                                   
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-text">Descrição do Problema</label>
                                        <div className="uk-form-controls">
                                        <textarea className="uk-textarea uk-form-large" id="form-stacked-text" type="text" disabled value={this.props.problema}></textarea>
                                        </div>
                                        </div>                                           
                                        </div>
                                        <div className="uk-flex  uk-flex-wrap uk-flex-wrap-around">
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <table className= "uk-table uk-table-divider">                                      
                                        <thead>
                                            <tr>
                                                <th className=" uk-text-bold uk-table-shrink">#</th>
                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Data</th>
                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Hora</th>
                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Atendente</th>
                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Contato</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody> 
                                              
                                        {this.state.assentamento.map( ass => { 
										var contato;
											// eslint-disable-next-line eqeqeq
											if(!ass['CAMPO9'] || ass['CAMPO9'] == 'undefined' || ass['CAMPO9'] === null){
													contato = ' ';
											}else{ contato = ass['CAMPO9'];}
                                                 return(
                                                <tr key={ass['CAMPO1']}>   
                                                <td><input className="uk-radio" type="radio" value={ass['CAMPO1']} checked={this.state.active === ass['CAMPO1'] ? true : false} onChange={() => {
                                                          this.setState({desc: ass['INSTRUCAO'], active: ass['CAMPO1']})
                                                }}/></td>
                                                <td>{moment(ass['CAMPO4']).format("DD/MM/YYYY")}</td>                                            
                                                <td>{moment(ass['CAMPO5']).format("LTS")}</td>
                                                <td>{ass['OPR']}</td>  
                                                <td className="uk-text-truncate">{contato}</td>
                                                </tr>
												)
                                                
                                                
										})}
                                          
                                            </tbody>
                                            </table>
                                             {this.state.load && <div uk-spinner></div>  } 
                                        </div>
                                         
                                        <div className="uk-width-1-2@m uk-width-1-1@s  uk-padding-small">
                                            <p>Descrição</p>
                                            <textarea className="uk-textarea uk-form-large" rows="8" value={this.state.desc} disabled></textarea>
                                        </div>
                                        </div>
                                      </div>
                                </form>
                    
                        
                            </div>
            </div>
            </div>
            
        );
    }
  
}
