import React, { useRef } from "react";
import { useSelector, useDispatch} from 'react-redux';
import {getOName} from '../services/auth';
import api from '../services/api';
import { toast } from "react-toastify";


export default function FilterChamados ({load,order}){
  order.current = 'default'
  const operadores = useSelector( state => state.operadores)
  const operador = useRef(getOName())
  const dispatch = useDispatch()

async function ChamadosByOperador(opr){
  load(true)
  dispatch({type: 'ADD_CHAMADOS', chamados: []})
  operador.current = opr
  if(opr === 0 || opr === "0" ){
      dispatch({type: 'ADD_OPERADORES', operadores: []})
      const todos = await api.get(`/opr/stch/0`)
      const oprs = await todos.data.map(opr=> opr.OPR).filter((value, index, self) => { 
        return self.indexOf(value) === index })
        dispatch({type: 'ADD_OPERADORES', operadores: [...oprs.sort()]})
        dispatch({type: 'ADD_CHAMADOS', chamados: [ ...todos.data]})
        load(false)
  }else{
   const chamados = await api.get(`/opr/stch/0`)
      if(chamados.data){
          const byopr = chamados.data.filter((value) => { return (value.OPR === operador.current || value.STATUS.trim() === 'A')})
          if(byopr.length > 0){
             dispatch({type: 'ADD_CHAMADOS', chamados: [ ...byopr]})
          }else{ 
            toast.error('Operador não possuí chamados')
          }
      }
      load(false)

  }               
}

  return(
    <div className="uk-width-1-4 uk-margin-left">
    <label className="uk-form-label text-light" htmlFor="filter">Filtrar Chamados</label>
    <div  className="uk-form-controls">
        <select  className="uk-select" id="filter" name="operador" value={operador.current} onChange={(e)=> ChamadosByOperador(e.target.value)}>
        <option value={0}>Todos os chamados</option>
            {operadores ?
              operadores.map((operador) => {
                return <option key={operador} value={operador}>{operador}</option>
              })
              : null
            }
            
        </select>
    </div>
    
    </div>
  )
}
