import React from 'react';
import Logo from '../assests/logo_tec.png';
import { logout } from '../services/auth';

export default class MenuClient extends React.Component {
  logout = () => {
      logout();
  }
  render() {
    return (
      <div>
          <nav className="navbar bg-white" color="white">
          <span className="navbar-brand d-flex"><a  href="http://tscom.com.br/"><img src={Logo} className="uk-visible@m" style={{width: '80%'}}  alt='Tec System'/></a></span>
          <div className="navbar uk-flex-1 uk-flex" style={{margin:'0px auto'}}>
              <ul className="navbar-nav justify-content-center uk-text-center" style={{margin:'0px auto'}}>
                <li className="nav-item">
                <h2 className="nav-link text-default">Controle de Chamados</h2>
            </li>
        </ul>
    </div>
    <div className="uk-flex uk-flex-"></div>
    <div className="uk-navbar-right">
    <div className="uk-margin-large-left uk-text-primary" style={{padding:'0px'}}>
        <p><span uk-icon="icon: mail"></span> suporte@tscom.com.br</p>
        <p><span uk-icon="icon: receiver"></span> (51)3039-8305</p>
      </div>
               <ul className="uk-navbar-nav uk-padding-small">   
               <li>
                <button className="uk-button uk-button-link" type="button"><i uk-icon="icon: user; ratio: 1"/> <span uk-icon="icon:  triangle-down"></span></button>
                    <div uk-dropdown="pos: bottom-justify" >
                        <ul className="uk-nav uk-dropdown-nav">
                            <li className="uk-active uk-text-middle"><a href="/user/perfil"><span uk-icon="icon: user; ratio: 0.8"></span> Perfil</a></li>
                            <li className="uk-active uk-text-middle"><a href="/user"><span uk-icon="icon: album; ratio: 0.8"></span> Dashboard</a></li>
                           {// <li className="uk-active uk-text-middle"><a href="/user/report"><span uk-icon="icon: list; ratio: 0.8"></span> Relatórios</a></li>
                           }
                            <li className="uk-nav-divider"></li>
                            <li className="uk-text-middle"><a className="uk-text-danger"  onClick={this.logout} href="/loginuser"><span uk-icon="icon: sign-out"></span> Logout</a></li>
                        </ul>
                    </div>
                    </li>   
                </ul>
                </div>
              </nav>
      </div>
    );
  }
}
