import React from "react";
import { BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import { isAuthenticated, isAuthenticated2, isOpr } from "./services/auth";

import LoginOpr from "./pages/LoginOpr";
import DashOpr from "./pages/DashOpr";
import Operador from "./pages/Operador";
import LoginUser from "./pages/LoginUser";
import DashUser from "./pages/DashUser";
import User from "./pages/User";
import AdminClient from "./pages/AdminClient";


const PrivateRouteOpr = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated2() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/loginopr", state: { from: props.location } }} />
      )
    }
  />
);
const PrivateRouteOprI = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if(isOpr()){
        return <Component {...props} />
      } else {
        return <Redirect to={{ pathname: "/dashopr", state: { from: props.location } }} />
    }
  }
  }
  />
);
const PrivateRouteUser = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/loginuser", state: { from: props.location } }} />
      )
    }
  />
);



const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/loginopr" component={LoginOpr} />
      <PrivateRouteOpr exact path="/operador" component={DashOpr} />
      <PrivateRouteOpr exact path="/dashopr" component={Operador} /> 
      <PrivateRouteOpr exact path="/admin"component={AdminClient}/>   
      <Route exact path="/loginuser" component={LoginUser} />
      <PrivateRouteUser exact path="/user" component={DashUser} />
      <PrivateRouteUser  path="/user/perfil" component={User} />
    </Switch>
  </BrowserRouter>
);

export default Routes;