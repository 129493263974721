/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useCallback, useEffect} from "react";
import { useSelector, useDispatch} from 'react-redux';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import moment from 'moment';
import 'moment/locale/pt-br'
import api from '../services/api';
import { toast } from 'react-toastify';
import { getOpr, getOName } from "../services/auth";
UIkit.use(Icons);
const adjustTime = (params) => moment(params).locale('pt-br')

export default function ModalTableOpr({order}) {
    const chamado = useSelector(state => state.chamado)
    const infTech = useSelector(state => state.clientes)
    const dispatch = useDispatch();
    const stats = useSelector(state => state.stats);
    const tservices = useSelector(state => state.services);
    const tproblema = useSelector(state => state.problemas);
    const [edit, SetEdit] = useState(true)
    const [seta, setA] = useState(false)
    const [infValue,SetInfValue] = useState();
    const [visible, SetVisible] = useState(false)
    const [txtbutton, SetTxtButton] = useState('Editar');
    const [classbtn, SetClassBtn] = useState('info');
    const [iconbtn, SetIconBtn ] = useState('file-edit');
    const [txtsave, SetTxtSave] = useState('Salvar');
    const [iconsave, setIconSave] = useState(<span uk-icon="icon: check"></span>);
    const [assentamento, SetAssentamento] = useState([]);
    const [active, SetActive] = useState();
    const [temp, setTemp] = useState(true);
    const [temp3,setTemp3] = useState(true);
    const [inf, setInf] = useState([]);
    const [txtEcaminhar,setTxtEncaminhar] = useState('Enviar Email')
    const mails = useSelector(state => state.emails)
    const [descproblem, SetDescProblem] = useState('');
    const [valid, setValid] = useState(true);
    const [ stat, setStat] = useState(null);
    const [ envia, setEnvia] = useState(false);            
    const [maile, SetMailE] = useState();
    const [causador, setCausador] = useState(null)
    const [problem, SetProblem] = useState('');
    const [concluido, SetConcluido] = useState(true);
    const [contat, SetContat] = useState(null);
    const [descprob,setDescprob] = useState();
    const [tproblemas, setTproblemas] = useState(null);
    const [tservico, setTservico] = useState(null);
    const [tedit, SetTEdit] = useState();
    const [partner,SetPartner] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [status, setStatus] = useState();
    const [oprid, setOprid] = useState();
    const [oprname, setOprname] = useState();
    const id_ch = chamado.length > 0 ? chamado[0].CAMPO1 : '';
    const t31cpo1 = chamado.length > 0? chamado[0].T31CPO1 : '';
    const cod_cli = chamado.length > 0 ? chamado[0].CODCLIENTE : '';
    const prob_ch = chamado.length > 0 ? chamado[0].TPROBLEMA : '';
    const tp_cli = chamado.length > 0 ? chamado[0].TPCLIENT : '';
    const n_os = chamado.length > 0 ? chamado[0].NOS : '';
    const n_cli = chamado.length > 0  ? chamado[0].FANTASIA : '';
    const nome_cliente = chamado.length > 0  ? chamado[0].CLIENTE : '';
    const n_email = chamado.length > 0 ? chamado[0].EMAIL : '';
    const n_tel = chamado.length > 0 ? chamado[0].TEL : '';
    const integra = chamado.length > 0 ? chamado[0].INTEGRA : '';
    const endereco = chamado.length > 0 ? chamado[0].ENDERECO : '';
    const num_end = chamado.length > 0 ? chamado[0].NUMPRED : '';
    const compl_end = chamado.length > 0 ? chamado[0].COMPLEMENTO : '';
    const bairro = chamado.length > 0 ? chamado[0].BAIRRO : '';
    const cidade = chamado.length > 0 ? chamado[0].CIDADE : '';

    const modaltable = useCallback(async ({id}) => {
            try{
                //Carrega assentamentos do chamado
                await api.get("/assent/opr/"+id).then(data => {
               SetAssentamento(data.data ? [ ...data.data] : [assentamento]);
               let a = (data.data[0] ? data.data[0]['INSTRUCAO'] : '');
               SetDescProblem(a)
               let t = (data.data[0] ? data.data[0]['CAMPO1'] : null)
               SetActive(t)

            }) 
                //Carrega informações técnicas do cliente
                const client = infTech.filter((value) => { return value.ID === chamado[0].CODCLIENTE && value.TP === chamado[0].TPCLIENT.trim() })
                const {T31CPO1, GRP } = client[0]
                const tech = await api.post("/inf_tec",{ grp: GRP, t31cpo1: T31CPO1})
                setInf(tech.data[0].info)
                
                //Carrega Atendido Por - Atendido Para 
                const getPartner = await api.post("/opr/partner", {cliente: cod_cli, tipo: tp_cli})
                console.log(getPartner.data[0])
                SetPartner(getPartner.data[0])

            }catch(error) {
                console.log(error)
                toast.error('Não foi possivel carregar alguns dados!')
            }
            return ()=> {
                SetAssentamento([])
                SetDescProblem('')
                SetActive('')
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[chamado])

        const chmodal = useCallback(()=> {
            setDescprob(chamado[0].PROBLEMA)
            setTproblemas(chamado[0].CODPROB)
            setTservico(chamado[0].CODSERVICO)
            SetTEdit(chamado[0].TCH.trim())
            setStatus(chamado[0].STATUS_TXT)
            setStat(chamado[0].STATUS.trim())
            SetContat('')
            SetEdit(true);
            SetTxtButton('Editar');
            setInf([]);
            SetPartner([]);
            SetInfValue("");
            SetClassBtn('info');
            SetIconBtn('file-edit');
            SetTxtButton('Editar');
            SetClassBtn('info');
            SetIconBtn('file-edit');
            var campos = document.querySelectorAll('#edits')
            for (var i=0; i<campos.length; i++) {
               campos[i].setAttribute("disabled","disabled");
            }
        },[chamado])
                   
        useEffect(()=> {         
               if(chamado.length > 0 ){
                modaltable({id: chamado[0].CAMPO1})
                chmodal()
               }
               return () => {
                SetEdit(true)
                UIkit.switcher('#id_opr').show(0)
                if(document.getElementById('id_opr').getElementsByClassName('uk-active')){
                    if(document.getElementById('id_opr').getElementsByClassName('uk-active').length > 0){
                        if(document.getElementById('id_opr').getElementsByClassName('uk-active')[0].innerText.toLowerCase() !== 'cliente'){                
                            document.getElementById('id_opr').getElementsByClassName('uk-active')[0].className = ''
                            document.getElementById('id_opr').getElementsByClassName('indexopr')[0].className = 'indexopr uk-active'
                        }
                    }
                }
                
            }
           // eslint-disable-next-line react-hooks/exhaustive-deps
           },[chamado])
           
                
                
    function editar(){
         var emailRegExp   = /^(([^<;>;()[\]\\.,;:\s@\"]+(\.[^<;>;()[\]\\.,;:\s@\"]+)*)|(\".+\"))@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        setValid(emailRegExp.test(n_email))
        SetEdit(!edit);
        setA(false)
        var campos = document.querySelectorAll('#edits')
        if(edit){
            SetTxtButton('Cancelar');
            SetClassBtn('danger');
            SetIconBtn('close');
            for (var i=0; i<campos.length; i++) {
                campos[i].removeAttribute("disabled");
            }
            if( chamado[0].STATUS.trim() == 'O'){
                campos[0].setAttribute("disabled","disabled") 
                campos[1].setAttribute("disabled","disabled") 
                campos[2].setAttribute("disabled","disabled") 
                //campos[3].setAttribute("disabled","disabled") 
                for(let index = 0; index < campos[3].options.length; index++){ 
                    if(campos[3].options[index].value == "O" || campos[3].options[index].value == "F" ){ 
                        campos[3].options[index].removeAttribute("disabled")
                    }else{ 
                        campos[3].options[index].setAttribute("disabled","disabled")   
                    } 
                }
            }
        }else{
            SetTxtButton('Editar');
            SetClassBtn('info');
            SetIconBtn('file-edit');
            // eslint-disable-next-line no-redeclare
            for (var i=0; i<campos.length; i++) {
                campos[i].setAttribute("disabled","disabled");
            }
        }
    }   

    
    async function encaminhar(){        
        const emails_opr = document.querySelector('input[name="email1"]:checked')
        try{
        setTxtEncaminhar('Enviando...')
        setEnvia(true)
        var select = document.getElementById('filter')
        const envio = await api.post('/encaminhar', {"id_ch": chamado[0].CAMPO1, maile,oprname,oprid})
        if(envio.status === 200){ toast.success('Chamado encaminhado com sucesso!') }
        const chamados = await api.get(`/opr/stch/0`)
        const oprs = chamados.data.map(opr => opr.OPR).filter((value, index, self) => { return self.indexOf(value) === index })
        dispatch({type: 'ADD_OPERADORES', operadores: [...oprs.sort()]})                      
        const byopr = chamados.data.filter((value) => { return (value.OPR === getOName() || value.STATUS.trim() === 'A')})        
          if(byopr.length > 0){
             dispatch({type: 'ADD_CHAMADOS', chamados: [ ...byopr]})
             select.value = getOName()
          }else{
            dispatch({type: 'ADD_CHAMADOS', chamados: [ ...chamados.data]})
            select.value = 0
          }
            setTxtEncaminhar('Enviar')
            setEnvia(false)
            if(emails_opr !== null || emails_opr !== undefined){ 
                document.querySelector('input[name="email1"]:checked').checked = false;
            }
            UIkit.modal("#consultar").hide()
        }catch(err){
            setTxtEncaminhar('Enviar')
            setEnvia(false)
            if(emails_opr !== null || emails_opr !== undefined){ 
                document.querySelector('input[name="email1"]:checked').checked = false;
            }
            console.log(err)
            toast.error('Erro ao encaminhar chamado')
            UIkit.modal("#consultar").hide()
        }
    }
    async function save (){
            if(seta){
                if(problem === null || !problem.trim()){
               toast.error('Informe a Instrução ',{
                   position: toast.POSITION.TOP_RIGHT,
                   autoClose: 3000, 
               })
               return 
           }else  if(!stat || stat == null || stat === undefined){
               toast.error('Informe o status corretamente',{
                   position: toast.POSITION.TOP_RIGHT,
                   autoClose: 3000, 
                })
                return
           }else if( stat == 'F' && assentamento[0] === undefined){
           toast.error('Necessário assentamento para concluir chamado!', {
                   position: toast.POSITION.TOP_RIGHT,
                   autoClose: 3000, 
                   pauseOnFocusLoss: false,
                   pauseOnHover:false
                });
                return        
           }else if(stat == 'F' && !causador ){
           toast.error('Informe Causador do Problema ', {
                   position: toast.POSITION.TOP_RIGHT,
                   autoClose: 3000, 
                   pauseOnFocusLoss: false,
                   pauseOnHover:false
                })
                return
         }else  if(stat == 'F' && !contat ){
               toast.error('Informe o Nome do Contato ',{
                   position: toast.POSITION.TOP_RIGHT,
                   autoClose: 3000, 
                })
                return 
       }else if(stat == 'R' && !contat){
           toast.error('Informe o Nome do Contato ',{
                   position: toast.POSITION.TOP_RIGHT,
                   autoClose: 3000, 
                })
                return 
        }
        }else if(!tproblemas || tproblemas === undefined){
                toast.error('Informe o tipo de problema',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, 
                });
                return
                }else if(!tservico || tservico === undefined){
                    toast.error('Informe o tipo de serviço',{
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000, 
                    });
                    return
                }else if(descprob === null || !descprob.trim()){
                    toast.error('Informe a descricao do problema',{
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000, 
                    });
                    return
                }
            let opr = getOpr();
            let opr_name = getOName();
            let date = adjustTime().format('YYYY-MM-DD');
            let time = adjustTime().format('LTS');
            const select = document.getElementById('filter')
            try{
                SetEdit(!edit); SetTxtSave('Salvando...');  setIconSave(<div uk-spinner></div>);
                await api.post('/opr/ass/'+id_ch, { seta, id_ch, cod_cli,n_os, endereco, num_end, compl_end,bairro,cidade,opr_name, nome_cliente, n_cli, prob_ch, tp_cli,active, date, time, opr, problem, n_tel, n_email, contat, visible, stat, causador, tedit, descprob, tservico, tproblemas, integra, t31cpo1})
                toast.success('Alterações salvas com sucesso!');
                setTemp(true)                        
                const chamados = await api.get(`/opr/stch/0`)
                if(chamados.data.length > 0){
                  const oprs = chamados.data.map(opr => opr.OPR).filter((value, index, self) => { return self.indexOf(value) === index })
                      dispatch({type: 'ADD_OPERADORES', operadores: [...oprs.sort()]})                      
                      const byopr = chamados.data.filter((value) => { return value.OPR === getOName() || value.STATUS.trim() === "A"})
                      if(byopr.length > 0){
                            dispatch({type: 'ADD_CHAMADOS', chamados: [ ...byopr]})
                            select.value = getOName()
                      }else{
                             dispatch({type: 'ADD_CHAMADOS', chamados: [ ...chamados.data]})
                             select.value = 0
                      }
                    }
                    order.current = 'default'
                    SetProblem(''); 
                    SetContat('');
                    setCausador(null)
                 var campos = document.querySelectorAll('#edits')
                 SetTxtButton('Editar'); SetClassBtn('info'); SetIconBtn('file-edit');
                 for (var i=0; i<campos.length; i++) {
                     campos[i].setAttribute("disabled","disabled");
                    }
                    SetTxtSave('Salvar'); 
                    setIconSave(<span uk-icon="icon: check"></span>);
                    SetEdit(!edit); 
                    if(stat !== 'A' && stat !== 'F' && stat !== 'O'){
                        if(stat === 'E'){setStatus('EM ATENDIMENTO')} 
                        if(stat === 'T'){setStatus('ATENDIMENTO TSP' )}
                        if(stat === 'R'){setStatus('AGUARDANDO FEEDBACK DO CLIENTE')}
                        if(stat === 'L'){setStatus('FALTA DE ENERGIA ELÉTRICA' )}
                        if(stat === 'M'){setStatus('EM ESTUDO')}
                       }else{ 
                           UIkit.modal("#consultar").hide();
                       } 
                       if(seta && stat !== 'F'){
                           const assent = await api.get("/assent/opr/"+chamado[0].CAMPO1)
                             SetAssentamento(assent.data[0] ? [ ...assent.data] : [assentamento]);
                             let a = (assent.data[0] ? assent.data[0]['INSTRUCAO'] : '');
                             SetDescProblem(a)
                             let t = (assent.data[0] ? assent.data[0]['CAMPO1'] : null)
                             SetActive(t)           
                       }

            }catch(err){                
                toast.error('Erro ao salvar alterações',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    containerId:'SaveChamado'
                  });
                  SetTxtButton('Editar'); SetClassBtn('info'); SetIconBtn('file-edit');
                  SetEdit(!edit); SetTxtSave('Salvar'); setIconSave(<span uk-icon="icon: check"></span>); SetProblem(''); SetContat(null);
                  console.log('erro2:',err) 
                  var campos = document.querySelectorAll('#edits')
                  for (var i=0; i<campos.length; i++) {
                     campos[i].setAttribute("disabled","disabled");
                  }
                  
            }        
    } 
    return (
        <div id="modalassent"> 
        <button className="uk-modal-close-default uk-button uk-button-text " type="button" uk-close ><span className="uk-text-danger" uk-icon="icon: close"></span></button>
                        <div className="uk-modal-header">
                                <h2 className="uk-modal-title">Atendendo Chamado N° { chamado[0].CAMPO1 }</h2>
                                <div className="uk-width-expand@s uk-flex uk-flex-row uk-flex-end">
                               <div className="uk-width-1 uk-flex-row uk-flex-1 uk-flex">
                                <div className="uk-text-justify uk-flex-center">
                                    <div className="uk-text-justify">
                                        <p><span className="title-modal"><span className="uk-text-bold">N° Chamado: </span> {chamado[0].CAMPO1}</span>
                                           <span className="title-modal"><span className="uk-text-bold">Status: </span> {chamado[0].STATUS_TXT} </span>
                                           <span className="title-modal"><span className="uk-text-bold">Incluído por: </span> {chamado[0].OPR}  </span>
                                           <span className="title-modal"><span className="uk-text-bold">Data: </span> {chamado[0].TIME}</span>
                                           <span className="title-modal"><span className="uk-text-bold">Hora: </span> {chamado[0].DATE}</span>
                                           <span className="title-modal"><span className="uk-text-bold">Operador: </span> {getOName()}</span>
                                        </p>
                                    </div>
                                </div>
                                
                                </div>
                             </div>                                                              
                             </div>
                             <div className="uk-flex uk-text-center uk-flex-row uk-flex-wrap uk-flex-wrap-around uk-flex-end">
                                    <div className="uk-width-1-1@m uk-width-1-1@s uk-text-right uk-padding-small">
                                        <button className={`btn btn-button btn-${classbtn} uk-text-right text-light uk-margin-left`}  onClick={() => { 
                                                SetEdit(!edit) 
                                                editar()
                                                }} ><span uk-icon={`icon: ${iconbtn}`}></span>{txtbutton}</button>
                                        <button className="btn btn-button btn-success uk-text-right text-light uk-margin-left" disabled={edit} onClick={() => save()}> {iconsave} {txtsave}</button>
                                        </div>
                            </div>
                          
                            <div className="uk-flex uk-flex-row ">
                                            <div className="uk-width-1-2@m uk-width-1-1@s uk-width-1-2 ">
                                                <div className="uk-form-label">Tipo de Chamado</div>
                                                <div className="uk-form-controls uk-flex uk-flex-row py-1" >
                                                        <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                                            <label><input id="tchs" className="uk-radio" type="radio"  disabled={edit} value="C" checked={tedit === 'C' ? true : false} onChange={(e) => SetTEdit("C")}/> Conserto </label>
                                                            <label><input id="tchs" className="uk-radio" type="radio"  disabled={edit} value="O" checked={tedit === 'O' ? true : false} onChange={(e) => SetTEdit("O")}/> Orçamento </label>
                                                            <label><input id="tchs" className="uk-radio" type="radio"  disabled={edit} value="R" checked={tedit === 'R' ? true : false} onChange={(e) => SetTEdit("R")}/> Reparo </label>
                                                            <label><input id="tchs" className="uk-radio" type="radio"  disabled={edit} value="I" checked={tedit === 'I' ? true : false} onChange={(e) => SetTEdit("I")}/> Instalação </label>
                                                            <label><input id="tchs" className="uk-radio" type="radio"  disabled={edit} value="S" checked={tedit === 'S' ? true : false} onChange={(e) => SetTEdit("S")}/> Suporte </label>
                                                            <label><input id="tchs" className="uk-radio" type="radio"  disabled={edit} value="D" checked={tedit === 'D' ? true : false} onChange={(e) => SetTEdit("D")}/> Desinstalação </label>
                                                            <label><input id="tchs" className="uk-radio" type="radio"  disabled={edit} value="M" checked={tedit === 'M' ? true : false} onChange={(e) => SetTEdit("M")}/> Manutenção </label>
                                                        </div>
                                                    </div>                                            
                                                </div>
                                                                                        
                 
                    <div className="uk-width-1-4@m uk-width-1-1@s uk-width-1-4 ">
                                                    <div className="uk-form-label">Causador do Problema</div>
                                                    <div className="uk-form-controls py-1">
                                                        <div className="uk-flex-column uk-flex px-2">
                                                            <label><input className="uk-radio" type="radio" name="radio2" disabled={concluido} onClick={() => setCausador("C")}/> Cliente </label>
                                                            <label><input className="uk-radio" type="radio" name="radio2" disabled={concluido} onClick={() => setCausador("P")}/> Prestador </label><br />
                                                        </div>
                                                    </div>
                     </div>
                    <div className="uk-width-1-3@m uk-width-1-2@s uk-width-1-3">
                        <div className="uk-width-1-1@m uk-width-1-1@s  uk-text-left uk-padding-small">
                                            <p className="uk-text-left" style={{fontSize: '13px', padding: 0}}>Legenda: </p>
                                            <p className="uk-text-left" style={{fontSize: '12px', padding: 0}}><span className="uk-icon-button"  style={{backgroundColor: '#008000', width: '15px', height: '15px'}}></span> Visivel para cliente/E-mail enviado</p>
                                            <p className="uk-text-left" style={{fontSize: '12px', padding: 0}}><span className="uk-icon-button"  style={{backgroundColor: '#e50000', width: '15px', height: '15px'}}></span> Oculto para cliente/E-mail não enviado</p>

                                        </div>
                    </div>
                    <div className="uk-width-1-3@m uk-width-1-2@s uk-width-1-3">
                        <div className="uk-width-1-1@m uk-width-1-1@s  uk-text-left uk-padding-small">
                            <div className="uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Nº da Tarefa</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input" disabled value={chamado[0].INTEGRA}/>
                                        </div>
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Data Agendamento</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input" disabled value={moment(chamado[0].AGENDAMENTO)?.format("DD/MM/YY HH:mm")}/>
                                        </div>
                                        </div>
                        </div>
                    </div>
                    
            </div>
                            
                <ul id="id_opr" className="uk-subnav uk-subnav-pill" uk-switcher={`connect: .switcher-container-opr; animation: uk-animation-slide-right-small;`} >
                        <li className="indexopr"><a href="#">Cliente</a></li>
                        <li><a href="#">Problema</a></li>
                        <li onClick={() => setA(true)}><a href="#">Atendimento</a></li>
                        <li><a href="#">Encaminhar</a></li>
                </ul>
                <ul className={`uk-switcher switcher-container-opr uk-margin`}>
                                <li>
                                <div className="uk-flex uk-width-1-1">
                                <div className=" uk-flex uk-width-1-1 uk-flex-wrap uk-flex-wrap-around ">
                                    <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label" htmlFor="form-stacked-select">Código</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input"  disabled value={chamado[0].CODCLIENTE}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Cliente</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input"  disabled value={chamado[0].CLIENTE}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Telefone</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input" disabled value={chamado[0].TEL}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Endereço</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input" disabled value={chamado[0].ENDERECO}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">N° Prédio</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input" disabled value={chamado[0].NUMPRED}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Bairro</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input"  disabled value={chamado[0].BAIRRO}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">UF</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input"  disabled value={chamado[0].UF}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Cidade</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input"  disabled value={chamado[0].CIDADE}/>
                                        </div>
                                        </div>
                                        { chamado[0].TPCLIENT == 2 ? 
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Nome Fantasia</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input"  disabled value={chamado[0].FANTASIA}/>
                                        </div>
                                        </div>
                                        :
                                        <> </>
                                        }
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Nome do Contato</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input" disabled value={chamado[0].CONTATO}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Telefone do Contato</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input" disabled value={chamado[0].CONTEL}/>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Email do Contato</label>
                                         <div className="uk-form-controls">
                                            <input className="uk-input" disabled value={chamado[0].EMAIL}/>
                                        </div>
                                        </div>
                                        { partner['T465CPO4'] !== 1 && partner['T465CPO4'] !== 2 ? 
                                            <span></span>
                                            :
                                            <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small">
                                            <label className="uk-form-label" htmlFor="form-stacked-select">Tipo de Atendimento</label>
                                            <div className="uk-form-controls" >
                                            { partner['T465CPO4'] == 1 ?
                                                <input className="uk-input" style={{backgroundColor:'#76d7ea', color:'#000'}} disabled 
                                                value={`Atendido por ${partner['PARCERIA']}`} />
                                                :
                                                <input className="uk-input" style={{backgroundColor:'#118C4F', color:'#000'}} disabled 
                                                value={`Atendido para ${partner['PARCERIA']}`}/>
                                            }
                                            </div>
                                            </div>
                                        }
                                        </div>
                                         <div className="uk-width-1-4 ">
                                            <div className="uk-width-expand@s uk-width-1-1">
                                             <table className="uk-table uk-table-justify  uk-table-divider">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="uk-text-bold uk-table-shrink">Informações Técnicas</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {inf.map((element,index) => {
                                                                            if(!inf){
                                                                                return (
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                )

                                                                            }else {
                                                                            return (
                                                                                <tr key={index}>  
                                                                                    <td><input className="uk-radio" type="radio" name='inftec' placeholder={element['key']} checked={active === element['key'] ? true : false} onChange={() => {
                                                                                        SetInfValue(element['value']);
                                                                                        SetActive(element['key'])
                                                                                    }} /></td>
                                                                                    <td>{element['key']}</td>
                                                                                </tr>
                                                                            ) }
                                                                        })}

                                                                    </tbody>
                                                                </table>
                                                                <label className="uk-form-label" htmlFor="form-stacked-text">Observação</label>
                                                                <div className="uk-form-controls">
                                                                    <textarea className="uk-textarea uk-form-large" rows="8" disabled value={infValue}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                    </div>                                                                                                            
                                                
                                </li>
                                <li>
                                <div className="uk-flex uk-flex-wrap uk-flex-wrap-around ">
                                <div className="uk-padding-small uk-width-1-3@m uk-width-1-1@s">
                                         <label className="uk-form-label" >Tipo de Serviço</label>
                                         <div className="uk-form-controls"
                                         onClick={() => setTemp3(false) }>
                                            <select className="uk-select" 
                                                value={tservico}
                                                onChange={(e) => setTservico(e.target.value)} disabled id="edits">
                                            {temp3 && <option>{chamado[0].TSERVICO}</option>
                                            }
                                            {tservices.map(service => (
                                              <option key={service.CAMPO1} value={service.CAMPO1}>{service.CAMPO2}</option>
                                            ))}                                    
                                            </select>
                                        </div>
                                        </div>
                                        <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" >Tipo de Problema</label>
                                         <div className="uk-form-controls">
                                            <select className="uk-select"  disabled 
                                                value={tproblemas}
                                                onChange={(e) => setTproblemas(e.target.value)} id="edits">
                                                    {
                                                      tproblema.map(prob => {
                                                      if (prob.CAMPO2 == tservico) {
                                                        return <option key={prob.CAMPO1} value={prob.CAMPO1}>{prob.CAMPO3}</option>
                                                        }
                                                    })}                                     
                                            </select>
                                        </div>
                                        </div>  
                                        <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" htmlFor="form-stacked-select">Checklist do Problema</label>
                                         <div className="uk-form-controls">
                                            <select className="uk-select" disabled id="form-stacked-select" >                                    
                                                   <option value=""></option>                                            
                                            </select>
                                        </div>
                                        </div>  
                                        <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label">Descrição do Problema</label>
                                        <div className="uk-form-controls">
                                        <textarea className="uk-textarea uk-form-large" id="edits" disabled type="text" 
                                        value={descprob} maxLength={2000} onChange={(e) => { setDescprob(e.target.value) }}></textarea>
                                        </div>  
                                        </div>
                                        </div>
                                </li>
                                <li>
                                <div className="uk-flex  uk-flex-wrap uk-flex-wrap-around">
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <label className="uk-form-label" >Status</label>
                                         <div className="uk-form-controls" 
                                               onClick={() => { setTemp(false); setA(true)}}>
                                            <select className="uk-select status" disabled id="edits"
                                                value={stat}
                                                onChange={(e) => {
                                                    setA(true);
                                                    setTemp(false)
                                                    setStat(e.currentTarget.value ? e.currentTarget.value : 'A' );
                                                    if(e.currentTarget.value == 'F') {                                                   
                                                        SetConcluido(false)
                                                    }
                                                }}
                                               > 
                                                    {temp && <option>ALTERAR STATUS</option>}

                                                    {stats.map( data => 
                                                            <option key={data.CAMPO4} name={data.CAMPO2} value={data.CAMPO4}>{data.CAMPO2}</option>
                                                    )}
                                            </select>
                                        </div>
                                        <div className="uk-form-controls">
                                                <input id="assent" name="assent" className="uk-checkbox" type="checkbox" 
                                                    onClick={(e) => {SetVisible(!visible); setA(true)}}  
                                                    disabled={edit}
                                                    checked={visible}
                                                    />
                                                     Exibir para Cliente 
                                        
                                            {!valid && <><br/> <span className="uk-text-meta uk-padding-left uk-text-warning">E-mail não será enviado para o cliente pois não é válido</span> </>}
                                        </div>                                        
                                        <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small"></div>
                                        <label className="uk-form-label" >Nome do Contato</label>
                                         <div className="uk-form-controls">
                                            <input type="text" className="uk-input" onClick={() => setA(true)} maxLength={50} id="edits" disabled placeholder='' value={contat} onChange={(e) => {SetContat(e.target.value);setA(true) }} />
                                        </div>
                                        <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small"></div>
                                        <label className="uk-form-label" >Instrução</label>
                                        <div className="uk-form-controls">
                                        <textarea className="uk-textarea uk-form-large" maxLength={2000}  type="text" id="edits"  disabled placeholder='' 
                                        value={problem} onChange={(e) => {SetProblem(e.target.value);setA(true)}} onClick={()=> setA(true)}>
                                        </textarea>
                                        <span className="uk-text-meta">{ !problem.trim() ? '0' : problem.length}/2000</span>
                                        </div>  
                                         </div>    
                                        <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                        <table className= "uk-table uk-table-divider">                                      
                                        <thead>
                                            <tr>
                                                <th className="uk-text-bold uk-table-shrink">#</th>
                                                <th className="uk-text-bold uk-table-shrink">Cliente</th>
                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Data</th>
                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Hora</th>
                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Atendente</th>
                                                <th className="uk-width-small uk-text-bold uk-table-shrink">Contato</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>      
                                        {assentamento.map( ass => 
                                        { var color;
                                            if(ass['CAMPO10'] == 'S' && ass['CAMPO13'] == 'S'){
                                                color = '#008000'
                                            }
                                            else{
                                                color = '#e50000'
                                            }
										var contato;
											// eslint-disable-next-line eqeqeq
											if(!ass['CAMPO9'] || ass['CAMPO9'] == 'undefined' || ass['CAMPO9'] === null){
													contato = ' ';
											}else{ contato = ass['CAMPO9'];}
                                                 return(
                                                <tr key={ass['CAMPO1']}>   
                                                <td><input className="uk-radio" type="radio" placeholder={ass['CAMPO1']} checked={active === ass['CAMPO1'] ? true : false} onChange={() => {
                                                          SetDescProblem(ass['INSTRUCAO']);
                                                          SetActive(ass['CAMPO1'])
                                                }}/></td>
                                                <td><span className="uk-icon-button"  style={{backgroundColor: color, width: '15px', height: '15px'}}></span></td>
                                                <td>{adjustTime(ass['CAMPO4']).utc().format("DD/MM/YYYY")}</td>                                            
                                                <td>{adjustTime(ass['CAMPO5']).format("LTS")}</td>
                                                <td>{ass['OPR']}</td>  
                                                <td className="uk-text-truncate">{contato}</td>
                                                </tr>
												)
                                                
                                                
										})}
                                          
                                            </tbody>
                                            </table>
                                            <label className="uk-form-label" htmlFor="form-stacked-text">Instrução</label>
                                        <div className="uk-form-controls">
                                            <textarea className="uk-textarea uk-form-large" rows="8" disabled value={descproblem}></textarea>
                                        </div>
                                        </div>
                                        
                                        </div>                               
                                </li>
                                <li>
                                <div className="uk-flex uk-text-center uk-flex-row uk-flex-wrap uk-flex-wrap-around uk-flex-center ">
                                <div className="uk-width-1-2@m uk-width-1-2@s uk-padding-small">
                                   <table className="uk-table uk-table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mails.map((mail,index) => {
                                            return(
                                            <tr key={index}>
                                            <td className="uk-text-left">
                                                <label id="mails">
                                                    <input className="uk-radio" type="radio" name="email1" id={mail.OPR_ID} operador={mail.CAMPO4} value={mail.CAMPO11} 
                                                        onClick={(e)=> { SetMailE(e.currentTarget.value); 
                                                            setOprid(e.currentTarget.id); 
                                                            setOprname(e.currentTarget.getAttribute('operador'));
                                                        }}
                                                    /> 
                                                </label>
                                            </td>
                                            <td>{mail.CAMPO4}</td>
                                            <td>{mail.CAMPO11}</td>
                                        </tr>
                                            )
                                        })

                                        }
                                        </tbody>
                                        </table>
                                        </div>                                
                                    </div>
                                    <div className="uk-width-1-1@m uk-width-1-1@s  uk-text-center uk-padding-small">
                                    <button className="uk-button uk-text-center uk-button-primary uk-border-rounded" disabled={envia} onClick={()=> encaminhar()}>{txtEcaminhar}</button>
                                    </div>
                                </li>
                            </ul>                        
                        </div>            
)}