import React, { Component} from "react";
import { withRouter } from "react-router-dom";
import MenuClient from '../../components/menuclient';
import Back from '../../assests/bg_usr.jpg';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Footer from '../../components/footer';
import ModalTable from '../../components/ModalTable';
import DatePicker, {registerLocale, getDefaultLocale, setDefaultLocale } from 'react-datepicker';
import pt from "date-fns/locale/pt";
import api from '../../services/api';
import { getGrupo, getMail, getToken } from "../../services/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment/locale/pt-br';
registerLocale("pt", pt);
setDefaultLocale(pt)
moment.locale('pt-br');
// loads the Icon plugin
UIkit.use(Icons);

let styles = {
    main:{
        backgroundImage: 'url('+Back+')',
        minWidht: '100vh',
        minHeight:'100vh',
        backgroundRepeat: 'no-repeat', 
        overflow: 'hidden',
    },
    btn:{
        margin: '10px'

    }

}
toast.configure({
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000, 
    pauseOnFocusLoss: false
}
)
let date = new Date();
//let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
class DashUser extends Component {
    
    constructor(props){ 
        super(props)
    this.state = {
      cli_id: [],      
      cliente: [],
       call: [],
       clientepred: null, 
       clientebrr: null,
       clienteuf: null,
       clienteend: null, 
       clientecid: null,
       email:getMail(),
        services: [],
        problema: [],
        status: [        
            {'CAMPO1': 1, 'CAMPO2': 'Aguardando Atendimento'}
        ],
        clienteid: null,
        clientename: null,
        tipo_c: null,
        tipo_s: null,
        tipo_p: null,
        contato: null,
        desc: '',
        button: 'Salvar',
        date:'',
        time:'',
        filter: [],
        error: '',
        assentamento: [],
        chops:true,
        dateto: date,
        datepara: date,
        msg:null,
        iconload: <span uk-icon="icon: search"></span>,
        user: localStorage.getItem("@SicadCHW-USER"),
        chf : null,
        times: [],
        } 
    }    
    componentWillMount(){
        
    }

    componentDidMount = () => {
        api.defaults.headers.common = {'Authorization': `Bearer ${getToken()}`}
        try{
            //chamados
            api.get("/os/"+getGrupo()+"").then(async (dat4) => {
                dat4.data ?  this.setState({call: [ ...dat4.data]}) : this.setState({call: []})
        })                   
            //tipo de serviços
             api.get('/tp_s').then(async (dat2) => {
                dat2.data ?  this.setState({services : [ ...dat2.data] }) : this.setState({services: []})
            })
            //tipo de problemas
            api.get('/tp_p').then(async (dat) => {
                    dat.data ?  this.setState({ problema : [ ...dat.data]}) : this.setState({problema: []})
            })
            //clientes
                api.get("/user/"+getGrupo()+"").then(async (dat3) => {
                     dat3.data ? this.setState({cliente : [ ...dat3.data]}) : this.setState({cliente: []})
            })    
  

        }catch(err){
            return err;
        }        
    }
    
    modal = () => {
        document.getElementById("Form").reset(); 
        UIkit.modal("#include").show();
        
    }
    handleChangeClient = (e) => { 
        this.setState({clienteid : e.target.value})
        // eslint-disable-next-line array-callback-return
        this.state.cliente.filter((id) => { 
            // eslint-disable-next-line eqeqeq
            if(id.CAMPO3 == e.target.value){
            this.setState({ clientename: id.CAMPO46, 
                            tipo_c: id.CAMPO4,
                            clientepred: id.CAMPO7, 
                            clientebrr: id.BAIRRO.toString('utf8'), 
                            clienteuf: id.CAMPO10, 
                            clienteend: id.CAMPO6.toString('utf8'), 
                            clientecid: id.CAMPO11,
                        })
            
        } 
    })  
}
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value})
        this.setState({date: moment().format("YYYY-MM-DD"), time: moment().format('LTS')})
        }
    handleChangeTp = (e) => {
            this.setState({ tipo_p : e.target.value})
            
        }
    handleCh =  (is) => {
           //const {chops} = this.state;
            this.setState({chops : is})
                
        }
        handleChf = async (e) => {
            e.preventDefault();
            const {dateto, datepara, msg} = this.state;
            var to = moment(dateto).format('YYYY-MM-DD');
            var para = moment(datepara).format('YYYY-MM-DD');
            this.setState({iconload: <div uk-spinner="ratio: 1"></div>})
            try{
                api.post("osfin/"+getGrupo(), {'dateto': to, 'datepara': para }).then((dados => {
                    var dada = dados.data
                    if(dados.data === '[]' || dada.length === 0 ){   
                        this.setState({msg: 'Não há registro para esta pesquisa'})
                        this.setState({iconload:<span uk-icon="icon: search"></span>})
                        toast.error(msg);
                    }else{
                    this.setState({times: [...dados.data]})
                    this.setState({msg: null})        
                    this.setState({iconload: <span uk-icon="icon: search"></span>})
                    }
                    
                })
                ).catch(error => {
                    this.setState({msg: 'Sem chamados concluídos neste periodo' })
                    this.setState({iconload: <span uk-icon="icon: search"></span>})
                    toast.error(msg);
                })
                
            }catch(err){
                console.log(err);
                this.setState({msg: 'Sem chamados concluídos neste periodo' })
                this.setState({iconload: <span uk-icon="icon: search"></span>})
                toast.error(msg);
            }


         }

     handleSubmit = async (e) => {
            e.preventDefault();            
            const { date, time, tipo_c, clienteid, clientename, email, tipo_s, tipo_p, contato , desc , user, clientebrr, clientecid, clienteend, clientepred, clienteuf } = this.state;
            console.log(`date: ${date}, time: ${time}, tipo_c: ${tipo_c}, email: ${email}, clienteid: ${clienteid}, clientename: ${clientename}, tipo_s: ${tipo_s}, tipo_p: ${tipo_p}, contato: ${contato}, desc: ${desc} , user: ${user}, clientebrr: ${clientebrr}, clientecid: ${clientecid}, clienteend: ${clienteend}, clientepred: ${clientepred}, clienteuf: ${clienteuf}`)
            if( clientename === null || !clientename.trim()){
                toast.error('Informe o Cliente',{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, 
                  });
              }else if(tipo_s == null || !tipo_s.trim() ){
                toast.error("Informe o tipo de Serviço",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, 
                  });
                  return;
                }else if(tipo_p == null || !tipo_p.trim()){
                    toast.error("Informe o tipo de problema",{
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000, 
                      });
                      return; 
               }else if(contato == null || !contato.trim()){
                toast.error("Informe o contato",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, 
                  }); 
                  return;
                }else if(desc == null || !desc.trim()){
                    toast.error("Informe a descrição do problema",{
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000, 
                      }); 
                      return;
                }else{
                    this.setState({button:<div uk-spinner="ratio: 1"></div>});
                    try{
                        await api.post('/user/ch', { user, date, time, email, tipo_c, clienteid, clientename, tipo_s, tipo_p, contato , desc, clientebrr, clientecid, clienteend, clientepred, clienteuf }).then((res) =>{
                            if(res.data.ch){
                            toast.success(res.data.ch, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 5000
                            }); 
                            toast.success(res.data.mail, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 5000
                            }); 
                        }
                    if(res.data.erro){
                        toast.error(res.data.erro,{
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000
                      }); 
                    }
                    this.setState({date: null, time: null, tipo_c:null, clienteid:null, clientename:null, tipo_s:null, 
                        tipo_p:null, contato:null , desc:'' })   
                    this.setState({button:'Salvar'})
                    document.getElementById("Form").reset();
                    //    this.setState({tipo_sax:'Rede', contato:'' , desc:''})
            }).catch(erros => {
            toast.error("Erro de Conexão Database",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, 
                  });
                  this.setState({date: null, time: null, tipo_c:null, clienteid:null, clientename:null, tipo_s:null, 
                    tipo_p:null, contato:null , desc:'' })   
                this.setState({button:'Salvar'})
                
            })
            await api.get("/os/"+getGrupo()+"").then(async (dat4) => {
                await this.setState({call: [ ...dat4.data]})
            })            
            }catch(error){
                toast.error("Erro ao Incluir Chamado",{
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, 
                  });
                this.setState({button:'Salvar'})
                  document.getElementById("Form").reset();
            //      this.setState({tipo_sax:'Rede', contato:'' , desc:''})
                
            }
        }
        }
   
        render(){  
            moment().locale('pt-br');
        let cliente = this.state.cliente;        
        const {chops,dateto, datepara, iconload} = this.state
        return(
            <div  className="uk-container-fluid uk-background-cover uk-background-fixed uk-flex  uk-flex-column uk-flex-1 " style={styles.main} uk-img="true">
            <MenuClient></MenuClient>
            <div className="uk-container-fluid">            
            <div className="uk-background-default uk-text-center uk-width-1 uk-margin uk-flex uk-flex-center uk-flex-row uk-border-rounded uk-box-shadow-medium uk-margin-top uk-padding-small" style={{width: '98%', height:'auto', marginRight:'auto', marginLeft:'auto'}}>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#ffff00', width:'25px', height:'25px'}}> </span> Aguardando Atendimento </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#ff0080', width:'25px', height:'25px'}}> </span> Em Atendimento </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: 'blue', width:'25px', height:'25px'}}> </span> Ordem de Serviço </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#008080', width:'25px', height:'25px'}}> </span> Atendimento TSP </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#008000', width:'25px', height:'25px'}}> </span> Aguardando Feedback do Cliente </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#e50000', width:'25px', height:'25px'}}> </span> Falta de Energia </div>
            <div className="uk-margin-small-right uk-text-small"><span className="uk-icon-button" style={{backgroundColor: '#000', width:'25px', height:'25px'}}> </span> Em Estudo </div>
           </div>
            <div className="uk-flex-right uk-flex uk-margin-right ">
                <button className="uk-button btn-success uk-border-rounded uk-box-shadow-xlarge  uk-text-center-left" type="button" onClick={() => this.modal()}><span uk-icon='icon: plus'></span> Incluir Chamado</button>      
            </div>
           <div className="uk-container-fluid">
            <div className="uk-text-center uk-width-1 uk-margin uk-flex uk-flex-center uk-flex-row">
                <button onClick={() => this.handleCh(true)}  className={(chops)? 'uk-button uk-button-primary uk-margin-right' : 'uk-button uk-button-warning uk-margin-right uk-text-muted' }>Chamados Abertos</button>
                <button onClick={() =>  this.handleCh(false)}  className={(!chops)? 'uk-button uk-button-primary uk-margin-right' : 'uk-button uk-button-warning uk-margin-right uk-text-muted' }>Chamados Concluídos</button>
            </div>
              <ToastContainer />
            {!chops ?
            <div className="uk-container-fluid">
            <div className="uk-text-center uk-margin uk-flex uk-flex-center uk-flex-row">
                        <div className="">
                            <div class="uk-margin">
                                <span className="uk-text-center uk-h2"></span>
                                        <DatePicker
                                            locale={getDefaultLocale()}
                                            selected={dateto}
                                            onChange={value => {
                                                this.setState({ dateto: value });
                                            }}
                                            dateFormat="d/MM/yy"
                                            className="uk-input"
                                            name="de"
                                            maxDate={date}
                                        />
                                    </div>
                                </div>
                                <div class="uk-margin-left uk-margin-right uk-margin-top">
                                <span className="uk-text-bold" style={{color: 'white'}}> ~ </span>
                                </div>
                                <div className="">
                                    <div class="uk-margin">
                                    <span className="uk-text-center uk-h2" style={{color: 'white'}}> </span>
                                        <DatePicker
                                            locale={getDefaultLocale()}
                                            selected={datepara}
                                            onChange={value => {
                                                this.setState({ datepara: value });
                                            }}
                                            dateFormat="d/MM/yy"
                                            className="uk-input"
                                            maxDate={date}
                                            />
                                         <button type="submit" className="uk-button uk-button-primary" onClick={(e) =>  this.handleChf(e)}> {iconload}</button>
                                        </div>                                        
                                    </div>
                            </div>
                        <div className="uk-text-center uk-text-small uk-flex uk-flex-center uk-flex-row uk-margin-small-top uk-padding-small" uk-grid>
                         <table className="uk-table uk-table-striped uk-text-small uk-table-middle uk-text-emphasis uk-background-default  uk-width-2-2@s uk-table-responsive uk-border-rounded uk-box-shadow-medium">
                             <thead>
                                 <tr>
                                     {//<th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Situação</th>
                                     }
                                     <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">N° Chamado</th>
                                     <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Problema</th>
                                     {//<th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Status</th>
                                     }
                                     <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Cliente</th>
                                     <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Tempo Chamado</th>
                                     <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Data Chamado</th>
                                     <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Perfil do Problema</th>
                                     <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Ações</th>
             
                                 </tr>
                             </thead>
                             <tbody className="uk-text-center uk-text-small">
                                 {this.state.times.map( function(ch,index){
                                       moment.locale('pt-br');
                                     return (
                                         <tr key={index}>
                                         {//<td><span className="uk-icon-button"  style={{backgroundColor: color }}></span></td>
                                         }
                                         <td>{ch['CAMPO1']}</td>
                                         <td>{ch['PROBLEMA']}</td>
                                         {//<td style={{display: cchf}}>{text}</td>
                                         }
                                         <td>{ch['CAMPO46']}</td>
                                         <td>{moment(""+ch['CAMPO2']+" "+ch['DATE']+"").startOf('minute').fromNow()}</td>
                                         <td>{moment(ch['CAMPO2']).format('L')}</td>
                                         <td>{ch['CAMPO3']}</td>
                                         <td>
                                             <ModalTable
                                                 key={index}
                                                 id={ch.CAMPO1}
                                                 tipo={ch.PROBLEMA}
                                                 status="Concluído"
                                                 cliente={ch.T182CPO8}
                                                 tempo={ch.CAMPO2}
                                                 data={ch.CAMPO2}
                                                 problema={ch.CAMPO19}
                                                 user={ch.CAMPO29}
                                                 problemat={ch.CAMPO3}
                                             />
                                         </td>
                                         </tr>
                                 )})}
                             </tbody>
                         </table>
                         </div>
                         </div>
                         :
                         
           <div className="uk-text-center uk-text-small uk-flex uk-flex-center uk-flex-row uk-margin-small-top uk-padding-small" uk-grid>
            <table className="uk-table uk-table-striped uk-text-small uk-table-middle uk-text-emphasis uk-background-default  uk-width-2-2@s uk-table-responsive uk-border-rounded uk-box-shadow-medium">
                <thead>
                    <tr>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Situação</th>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">N° Chamado</th>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Problema</th>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Status</th>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Cliente</th>
                        <th className="uk-table-shrinsk uk-text-emphasis uk-preserve-width uk-text-center">Tempo Chamado</th>
                        <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Data Chamado</th>
                        <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Perfil do Problema</th>
                        <th className="uk-table-shrisnk uk-text-emphasis uk-preserve-width uk-text-center">Ações</th>

                    </tr>
                </thead>
                <tbody className="uk-text-center uk-text-small">
                    {this.state.call.map( function(ch,index){
                        var color
                        var text
                        var cchf;
                    if(ch.CAMPO28 === 'A'){
                           color = '#ffff00'
                           text = 'Aguardando Atendimento'
                           cchf = 'null'
                        }
                    if(ch.CAMPO28 === 'E'){
                            color = '#ff0080'
                            text = 'Em Atendimento'
                            cchf = 'null'
                          }
                    if(ch.CAMPO28 === 'O'){
                            color = 'blue'
                            text = 'Ordem de Serviço'
                            cchf = 'null'
                          }
                          if(ch.CAMPO28 === 'F'){
                             color = 'green'
                             text = 'Concluído'
                             cchf = 'none'
                          }
                    if(ch.CAMPO28 === 'T'){
                             color = '#008080'
                             text = 'Em Atendimento TSP'
                             cchf = 'null'
                          }
                    if(ch.CAMPO28 === 'R'){
                             color = '#008000'
                             text = 'Aguardando Feedback do Cliente'
                             cchf = 'null'
                          }
                    if(ch.CAMPO28 === 'L'){
                             color = '#e50000'
                             text = 'Falta de Energia'
                             cchf = 'null'
                          }
                    if(ch.CAMPO28 === 'M'){
                             color = '#000'
                             text = 'Em Estudo'
                             cchf = 'null'
                          }
                          moment.locale('pt-br');
                        return (
                            <tr key={index}>
                            <td><span className="uk-icon-button"  style={{backgroundColor: color }}></span></td>
                            <td>{ch['CAMPO1']}</td>
                            <td>{ch['PROBLEMA']}</td>
                            <td style={{display: cchf}}>{text}</td>
                            <td>{ch['CAMPO46']}</td>
                            <td>{moment(""+ch['CAMPO2']+" "+ch['DATE']+"").startOf('minute').fromNow()}</td>
                            <td>{moment(ch['CAMPO2']).format('L')}</td>
                            <td>{ch['CAMPO3']}</td>
                            <td>
                                <ModalTable
                                    key={index}
                                    id={ch.CAMPO1}
                                    tipo={ch.PROBLEMA}
                                    status={text}
                                    cliente={ch.T182CPO8}
                                    tempo={ch.CAMPO2}
                                    data={ch.CAMPO2}
                                    problema={ch.CAMPO19}
                                    user={ch.CAMPO29}
                                    problemat={ch.CAMPO3}
                                />
                            </td>
                            </tr>
                    )})}
                </tbody>
            </table>
            </div>            
            }
                <div className="uk-flex-right uk-flex uk-margin-right ">
                <> 
            <div id="include" uk-modal style={{display:'none'}} bg-close="false">
                <div className="uk-modal-dialog uk-modal-body uk-width-1-1 uk-height-auto uk-border-rounded">
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">Incluir Chamado</h2>
                    </div>
                    <form id="Form" className="uk-form-stacked uk-flex-center uk-flex-row">
                        <div className="uk-width-expand@m">
                            <div className="uk-flex uk-flex-wrap uk-flex-wrap-around ">
                                <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small ">
                                    <label className="uk-form-label" htmlFor="form-stacked-select">Cliente</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="form-stacked-select" name="cliente" onChange={(e) => this.handleChangeClient(e)}  required>
                                            <option >Selecionar Cliente</option>
                                            { cliente.map( cli => (                                            
                                                   <option key={cli.CAMPO3} value={cli.CAMPO3} data={cli.CAMPO3}> {cli.CAMPO46} - {cli.CAMPO3}</option>
                                               ))}
                                            </select>
                                        
                                        </div>
                                </div>
                                <div className="uk-padding-small uk-width-1-3@m uk-width-1-1@s">
                                    <label className="uk-form-label" htmlFor="form-stacked-select" required>Tipo de Serviço</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="form-stacked-select" name="tipo_s" value={this.state.tipo_s} onChange={this.handleChange} required>
                                                <option>Selecionar Tipo de Serviço</option>
                                                {this.state.services.map( service => (
                                                   <option key={service.CAMPO1}  value={service.CAMPO1}>{service.CAMPO2}</option>
                                                ))}
                                            </select>
                                        </div>
                                </div>
                                <div className="uk-width-1-3@m uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label" htmlFor="form-stacked-select" required>Tipo de Problema</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="form-stacked-select" name="tipo_p" value={this.state.tipo_p} onChange={this.handleChangeTp} required>
                                            <option >Selecionar Tipo de Problema</option>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                this.state.problema.map((res) => {
                                                // eslint-disable-next-line eqeqeq
                                                if(res.CAMPO2 == this.state.tipo_s){
                                                    return(<option key={res.CAMPO1} value={res.CAMPO1}>{res.CAMPO3}</option>)
                                                }
                                            })}
                                            </select>
                                        </div>
                                </div>
                            </div>
                            <div className="uk-flex uk-flex-wrap uk-flex-wrap-around ">
                                <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label" htmlFor="form-stacked-select">Status</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" id="form-stacked-select" disabled >
                                            {this.state.status.map( stats => (
                                                   <option key={stats.CAMPO1}>{stats.CAMPO2}</option>
                                               ))}
                                            </select>            
                                        </div>
                                </div>
                                <div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label " htmlFor="form-stacked-text" required>Nome do Contato</label>
                                        <div className="uk-form-controls">
                                        <input className="uk-input" id="form-stacked-text" type="text" name="contato" value={this.state.contato} onChange={this.handleChange} required/>
                                        </div>
                                    </div>
                            </div>
                            <div className="uk-flex  uk-flex-wrap uk-flex-wrap-around ">
                                <div className="uk-width-1-1@m uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label " htmlFor="form-stacked-text" required>Descrição do Problema</label>
                                        <div className="uk-form-controls">
                                            <textarea className="uk-textarea uk-form-large" id="form-stacked-text" value={this.state.desc}type="text" name="desc" maxlength='2000'  onChange={this.handleChange} />
                                        </div>
                                         <span className="uk-text-meta">{!this.state.desc.trim() ? '0' : this.state.desc.length}/2000</span>
                                </div>        
                                {/*<div className="uk-width-1-2@m uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label " htmlFor="form-stacked-text">Instrução</label>
                                        <div className="uk-form-controls">
                                            <textarea className="uk-textarea uk-form-large" id="form-stacked-text" type="text"></textarea>
                                        </div>
                                            </div>*/}
                                    
                                </div>
                        </div>
                    </form>
                    <div className="uk-modal-footer">
                        <p className="uk-text-right  ">
                            <button id="cancel" style={styles.btn} cancel="" className="uk-button uk-button-danger uk-border-rounded uk-modal-close" type="button"><span uk-icon='icon: close'></span> Cancelar</button>
                            <button id="save" style={styles.btn} onClick={this.handleSubmit}  className="uk-button btn-success uk-border-rounded" type="button"><span uk-icon='icon: check'></span> {this.state.button}</button>
                        </p>
                    </div>
                </div>
                <ToastContainer />
             </div>
             </>
                </div>
                <Footer></Footer>
            </div>
            </div>
            </div>
        );
    }
}
export default withRouter(DashUser);

