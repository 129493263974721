import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Back from '../../assests/bg_opr.jpg';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { login, setOpr, setOName, setDep , setTpOpr } from '../../services/auth';
import api from '../../services/api';
//import socketIOClient from "socket.io-client";
UIkit.use(Icons);

let styles = {
	main: {
		backgroundImage: 'url(' + Back + ')',
		backgroundRepeat: 'no-repeat',
		minHeight: '100vh',
		backgroundSize: 'cover'
	},
	card: {
		borderRadius: '10px',
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.29)'
		//background: 'rgba(255,255,255,0.89)'
	},
	labels: {
		borderRadius: '10px',
		color: 'black'
	},
	btn: {
		borderRadius: '10px'
	}
};

function LoginOpr(props) {
	const [ user, setUser ] = useState();
	const [ password, setPassword ] = useState();
	const [ load, setLoad ] = useState('Login');
	const [ msg, setMsg ] = useState(null);

	async function handleLogin(e) {
		e.preventDefault();
		if (!user || !password) {
			setMsg('Preencha as credenciais!');
			toast.error(msg, {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 2000,
				containerId: 'LoginOperador'
			});
		} else {
			setLoad(<div uk-spinner="ratio: 1" />);
			try {
				const response = await api.post('/auth/opr', { user, password }) 
				console.warn(response.status,response.statusText)
				login(response.data.token);
				setOpr(response.data.id);
				setOName(response.data.name);
				setDep(response.data.departamento)
				setTpOpr(response.data.tpopr);				
				setLoad('Login');
				props.history.push('/dashopr');
			} catch (err) {
				toast.error('Login Inválido', {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 2000,
					containerId: 'LoginOperador'
				});
				setLoad('Login');
			}
		}
	}
	return (
		<div className="uk-flex uk-flex-column uk-flex-1" style={styles.main}>
			<Menu></Menu>
			<div>
				<div
					className="uk-card uk-card-default uk-text-center uk-align-center uk-width-1-3@m uk-width-1-2@s uk-margin-large-top"
					style={styles.card}
				>
					<div className="uk-card-header">
						<div className="uk-grid-small uk-flex-middle" uk-grid="true">
							<div className="">
								<h3 className="uk-card-title uk-margin-remove-bottom uk-center">Login - Operador</h3>
							</div>
						</div>
					</div>
					<div className="uk-card-body">
						<form onSubmit={(e) => handleLogin(e)}>
							<div className="uk-margin">
								<div className="uk-inline">
									<span className="uk-form-icon" uk-icon="icon: user" />
									<input
										className="uk-input uk-form-width-large uk-text-uppercase"
										type="text"
										maxLength={10}
										onChange={(e) => setUser(e.target.value.toUpperCase())}
										placeholder="Username"
										style={styles.labels}
									/>
								</div>
							</div>
							<div className="uk-margin">
								<div className="uk-inline">
									<span className="uk-form-icon" uk-icon="icon: lock" />
									<input
										className="uk-input uk-form-width-large"
										type="password"
										maxLength={10}
										onChange={(e) => setPassword(e.target.value)}
										placeholder="Senha"
										style={styles.labels}
									/>
								</div>
							</div>
							<button
								className="uk-button uk-button-primary uk-background-primary"
								type="submit"
								style={styles.btn}
							>
								{load}
							</button>
						</form>
					</div>
				</div>
			</div>
			
				<Footer></Footer>
			
			<ToastContainer enableMultiContainer containerId={'LoginOperador'} position={toast.POSITION.TOP_RIGHT} />
		</div>
	);
}

export default withRouter(LoginOpr);
