import React, { Component} from "react";
import { withRouter } from "react-router-dom";
import Back from '../../assests/bg_usr.jpg';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import  MenuClient from '../../components/menuclient';
import Footer from '../../components/footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import api from '../../services/api';
import { getUser, getId } from "../../services/auth";
import moment from 'moment';
moment().locale('pt-br');
// loads the Icon plugin
UIkit.use(Icons);

let styles = {
    main:{
        backgroundImage: 'url('+Back+')',
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat' , 
    },
    btn:{
        margin: '10px'

    }
}

class User extends Component {
    state = {
        button:'Alterar Senha',
        user: null,
        id:null,
        pass:null,
        pass2:null,
        email:null,
    }
    componentDidMount = () => {
        this.setState({user: getUser(), id: getId()})
    }
    BtnHandleChangePassword = async (e) => {
        
        e.preventDefault();
        const { id, user, pass, pass2, email } = this.state;
        if(id === null){
            toast.error("Erro ao Identificar Usuário", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, 
              });
        }else if(pass !== pass2 ){
                toast.error("Senhas não conferem", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, 
              });
        }else{
            try{
            const response =  await api.post('/auth/user/pwd',{ id, user, pass, email});
            var erro = response.data.error
            var msg = response.data.data
			this.setState({button:<div uk-spinner="ratio: 1"></div>});
            if(erro){
                toast.error(erro, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, 
                  });
            }else{
            toast.success(msg, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000
              });
              this.setState({button:'Alterar'});
              document.getElementById("Form").reset();
            }
    }catch(err){
		this.setState({button:'Alterar'});
        toast.error("Não foi possivel a troca de senha", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000, 
          });
    }
        }
        }
    render(){
        return(
            <div className="uk-container-fluid uk-background-cover uk-flex uk-flex-column uk-flex-1" style={styles.main} uk-img>
            <MenuClient></MenuClient>
            <div className="uk-container-fluid">            
            <div className="uk-card uk-card-default uk-text-center uk-align-center uk-width-1-3@m uk-width-1-2@s uk-background-default uk-margin-large-top uk-border-rounded uk-box-shadow-large" style={styles}>
                <div className="uk-card-header">
                    <div className="uk-grid-small uk-flex-middle" uk-grid>
                            <h3 className="uk-card-title uk-margin-remove-bottom uk-center">Perfil - Usuário</h3>
                    </div>
                </div>
                <div className="uk-card-body">
                 <form id="Form">
                 <div className="uk-margin">
                    <div className="uk-inline ">
                        <span className="uk-form-icon" uk-icon="icon: user"></span>
                        <input className="uk-input uk-form-width-large uk-border-rounded" style={{background: '#e3e4e4'}}  type="text" value={this.state.user} disabled />
                    </div>
                    </div>
                    <div className="uk-margin">
                        <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="icon: mail"></span>
                        <input className="uk-input uk-border-rounded uk-form-width-large" type="email" placeholder="Alterar E-mail" onChange={(e) => this.setState({email : e.target.value})}/>
                    </div>
                    </div>
                    <div className="uk-margin">
                    <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="icon: lock"></span>
                        <input className="uk-input uk-form-width-large uk-border-rounded" type="password" placeholder="Nova Senha" onChange={(e) => this.setState({pass : e.target.value.toUpperCase()})}/>
                    </div>
                    </div>
                    <div className="uk-margin">
                        <div className="uk-inline">
                        <span className="uk-form-icon" uk-icon="icon: lock"></span>
                        <input className="uk-input uk-border-rounded uk-form-width-large" type="password" placeholder="Repita a Nova Senha"onChange={(e) => this.setState({pass2 : e.target.value.toUpperCase()})}/>
                    </div>
                    </div>
                    
                    <button  className="btn btn-button btn-warning text-light" onClick={this.BtnHandleChangePassword} style={styles.btn}>{this.state.button}</button>
                    <ToastContainer />
                </form>
                </div>
            </div>
            </div>
            <Footer></Footer>
            </div>
           );
    }
            
}
export default withRouter(User);