import React, { useState, useEffect,useRef} from "react";
import { withRouter } from "react-router-dom";
import UIkit from 'uikit';
import Back from '../../assests/bg_usr.jpg';
import Icons from 'uikit/dist/js/uikit-icons';
import MenuOp from '../../components/menuop';
import Footer from '../../components/footer';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import moment from 'moment';
import * as yup from 'yup'; 

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
let schema = yup.object().shape({
    email: yup.string().email('Email Não Válido'),
    phone: yup.string().matches(phoneRegExp, 'Número de telefone inválido').min(8,'Telefone Inválido'),
    password: yup.string('Senha Requerida').min(3, 'Senha muito curta').nullable().required("Senha é um campo requerido")
  });


moment().locale('pt-br');
UIkit.use(Icons);
let styles = {
    main:{
        backgroundImage: 'url('+Back+')',
        backgroundRepeat: 'no-repeat' , 
        minHeight:'100vh',
        backgroundSize: 'cover',
    }, 
    card: {
        borderRadius: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.29)',       
        
     },
     labels: {
         borderRadius: '10px',
         color: 'black',
     },
}

function AdminClient (){
    const [users,setUsers] = useState([]);
    const [searchusers,setSearchusers] = useState([]);
    const totalusers = useRef();
    const [page,setPage] = useState(0);
    const search = useRef();
    const pages = useRef();
    const [currentUser,setCurrentUser] = useState(null);


    async function load(){
        try{
            const total = await api.get('/admin/count/users');
            totalusers.current = total.data[0]["TotalUsers"];
            pages.current = Math.ceil(totalusers.current/10);
        }catch(err){ 
            toast.error("Erro ao Buscar dados", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000, 
            containerId: 'Admin'
            })
        }
    }
    async function loadusers(){
        try{
            const usersdto = await api.get(`/admin/users/${page}`)
            pages.current = Math.ceil(totalusers.current/10);
            setUsers(...usersdto.data)
         }catch(err){ 
                    toast.error("Erro ao Buscar dados", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000, 
                    containerId: 'Admin'
                    })
                }
            }

    useEffect(() => { load() },[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { loadusers() }, [page])  

    function nextPage(){
        if(page >= pages.current-1 || searchusers.length > 0) return
        setPage((prevState) => prevState+1); 
    }
   function prevPage(){
        if(pages.current <= 0 || page <= 0 || searchusers.length > 0) return
        setPage((prevState) => prevState-1); 
   }
   async function deleteSearch(e){
        e.preventDefault()
        document.getElementById("search").value = ""
        pages.current = 0 
        search.current = null
        setSearchusers([]) 
        await load()
        await loadusers()
    return
    }

   async function searchUsers(e){
       e.preventDefault()
       pages.current = 0 
       // eslint-disable-next-line eqeqeq
       if(search.current == undefined || search.current == null || !search.current.trim() ){
            setSearchusers([]) 
            await load()
            await loadusers()
            return
       }
       try{
        const resultUsers = await api.post(`/admin/search`, {search: search.current.toLowerCase()})
            totalusers.current = resultUsers.data.length
            // eslint-disable-next-line eqeqeq
            if(totalusers.current == 0 ){
                toast.warn("Nada encontrado", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000, 
                    containerId: 'Admin'
                    })
                    return
            }
            setSearchusers(...[resultUsers.data])
            setPage(0)
       }catch(error){
        toast.error("Erro ao Buscar dados", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000, 
            containerId: 'Admin'
            })
       }
   }
   function confirmDeleteUser(user){;
        setCurrentUser(user)
        UIkit.modal("#modal-exclude").show()
   }
   
   function confirmEditUser(user){ 
        document.getElementsByName("password")[0].value = ""
        document.getElementsByName("email")[0].value = ""
        document.getElementsByName("phone")[0].value = ""
        document.getElementsByName("user")[0].value = ""
        setCurrentUser(user)
        UIkit.modal("#modal-edit").show()
}

async function editUser(){
    if(!currentUser) return
        try {
            const valid = await schema.validate({email: currentUser.email, password: currentUser.password, phone: currentUser.phone})
            console.log(valid)
            if(!valid) return
            await api.post(`/admin/user/${currentUser.id}`, {user: currentUser})
            toast.success("Usuário alterado com sucesso!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, 
                containerId: 'Admin'
            })
                UIkit.modal("#modal-edit").hide()
                setSearchusers([]) 
                document.getElementById("search").value = ""
                await load()
                await loadusers()

        } catch (error) {
            console.log(error)
            toast.error( error.message || "Não foi possível editar este usuário", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, 
                containerId: 'Admin'
                })
                if(!error.message) return
        }
   }
   async function deleteUser(){
    if(!currentUser) return
        try {
            await api.delete(`/admin/user/${currentUser}`)
            toast.success("Usuário excluído com sucesso!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, 
                containerId: 'Admin'
                })
                UIkit.modal("#modal-exclude").hide()
                setCurrentUser(null)
                document.getElementById("search").value = ""
                pages.current = 0 
                search.current = null
                setSearchusers([]) 
                await load()
                await loadusers()

        } catch (error) {
            toast.error("Não foi possível excluir este usuário", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000, 
                containerId: 'Admin'
                })
                UIkit.modal("#modal-exclude").hide()
                setCurrentUser(null)
        }
    }
    async function switchData(key,value){
        setCurrentUser(prevState => ({ ...prevState, [key]:value }));
    }

return(
    <div className="uk-flex uk-flex-column uk-flex-1 uk-background-fixed uk-container-fluid uk-background-cover uk-background-blend-multiply" style={styles.main} uk-img='true'>
    <MenuOp></MenuOp>
    <div className="uk-container-fluid">            
      <div className="uk-text-center uk-flex uk-flex-center uk-flex-row" >
            <div className="uk-width-2-3@m uk-padding-small uk-margin-large-top">
                <div className="uk-card uk-card-default uk-background-default uk-text-emphasis uk-card-body" style={styles.card}>
                    <h1 className="uk-heading-divider uk-text-center uk-text-large">Gerenciar Usuários</h1>
                    <div className="uk-overflow-auto uk-text-small uk-text-left uk-width-1-1@m uk-align-center">
                    <div className="uk-flex uk-flex-around uk-flex-middle uk-width-1-1 uk-padding-small">
                    <form className="uk-search uk-search-default uk-width-1-1 uk-flex uk-flex-around">
                        <input id="search" onChange={(target) => search.current = target.currentTarget.value} className="uk-search-input uk-width-4-5" type="search" placeholder="Pesquisar ex: grupo, usuario, email, sigla" />
                        <button onClick={(e)=> searchUsers(e)}  className="uk-button btn-info uk-button-small uk-border-rounded"><span uk-icon='icon: search'></span> </button>
                        {search.current?.length > 0 ? <button onClick={(e)=> deleteSearch(e)}  className="uk-button btn-danger uk-button-small uk-border-rounded"><span uk-icon='icon: close'></span></button> : null}
                    </form>
                    <div className="uk-text-meta uk-text-right uk-width-1-4">Total de usuários: {totalusers.current}</div>
                    </div>
                    <table className="uk-table uk-table-divider uk-table-responsive">
                        <thead>
                        <tr>
                            <th>Grupo</th>
                            <th>Usuário</th>
                            <th>Email</th>
                            <th>Sigla</th>
                            <th>Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                     {searchusers.length > 0 ? 
                        searchusers.map(user =>
                        <tr key={user.id}>
                        <td>{user.empresa}</td>
                        <td>{user.user}</td>
                        <td>{user.email}</td>
                        <td>{user.sigla}</td>
                        <td className="uk-padding-small uk-flex uk-flex-around">
                            <button onClick={() => confirmEditUser(user) } className="uk-button uk-button-text" type="button" ><i className="uk-text-warning" uk-icon="icon: pencil; ratio: 0.9"></i></button>  
                            <button onClick={() => confirmDeleteUser(user.id) } className="uk-button uk-button-text" type="button" ><i className="uk-text-danger" uk-icon="icon: trash; ratio: 0.9"></i></button>  
                        </td>
                    </tr>
                     ) : users.map(user =>
                        <tr key={user.id}>
                        <td>{user.empresa}</td>
                        <td>{user.user}</td>
                        <td>{user.email}</td>
                        <td>{user.sigla}</td>
                        <td className="uk-padding-small uk-flex uk-flex-around">
                            <button onClick={() => confirmEditUser(user) } className="uk-button uk-button-text" type="button" ><i className="uk-text-warning" uk-icon="icon: pencil; ratio: 0.9"></i></button>  
                            <button onClick={() => confirmDeleteUser(user.id) } className="uk-button uk-button-text" type="button" ><i className="uk-text-danger" uk-icon="icon: trash; ratio: 0.9"></i></button>  
                        </td>
                    </tr>
                     )
                     }
                    </tbody>
                </table>
                <div className="uk-align-right uk-text-right uk-flex uk-flex-middle">
                <button className="uk-button uk-button-link uk-margin-right" onClick={()=> prevPage()}><i className="uk-text-primaty" uk-icon="icon: chevron-left; ratio: 0.9"></i></button>
                <div className="uk-text-meta uk-text-center">Página {page+1} de {pages.current || page+1}</div>
                <button className="uk-button uk-button-link uk-margin-left" onClick={()=> nextPage()}><i className="uk-text-primaty" uk-icon="icon: chevron-right; ratio: 0.9"></i></button>
                </div>
                </div>
                    <ToastContainer enableMultiContainer containerId={'Admin'} position={toast.POSITION.TOP_RIGHT} />
                    <div id="modal-exclude" uk-modal="true" style={{display:'none'}}>
                            <div className="uk-modal-dialog uk-modal-body">
                            <h2 className="uk-modal-title">Deseja excluir este usuário?</h2>
                            <p className="uk-text-right uk-flex uk-flex-around">
                                <button className="uk-button uk-button-default uk-modal-close" type="button">Cancelar</button>
                                <button className="uk-button uk-button-danger" onClick={()=> deleteUser() } type="button">Excluir</button>
                            </p>
                        </div>
                    </div>
                    <div id="modal-edit" uk-modal="true" bg-close="false" style={{display:'none'}}>
                            <div className="uk-modal-dialog uk-modal-body">
                            <h2 className="uk-modal-title uk-text-center">Editar Usuário {currentUser?.user}</h2>
                            <div className="uk-margin">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon" uk-icon="icon: user"></span>
                                        <input className="uk-input" maxLength="10" type="text" placeholder={currentUser?.user} name="user"  autoComplete="off" onChange={(e) => switchData("user", e.target.value.toUpperCase())} />
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon"  uk-icon="icon: mail"></span>
                                            <input className="uk-input" type="email" placeholder={currentUser?.email} autoComplete="off" name="email" style={styles.labels} onChange={(e) => switchData("email",e.target.value)}/>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon"  uk-icon="icon: receiver"></span>
                                            <input className="uk-input" type="tel" placeholder={currentUser?.phone} autoComplete="off" name="phone" style={styles.labels} onChange={(e) => switchData("phone",e.target.value)}
                                                maxLength="14" mask="\([0-9]{2}\) [0-9]{4,6}-[0-9]{3,4}$"
                                        />
                                    </div>
                                </div>
                                <div className="uk-margin">
                                <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon"  uk-icon="icon: lock"></span>
                                        <input className="uk-input" maxLength="10" type="password" placeholder="Senha de Usuário" autoComplete="off" onChange={(e) => switchData("password",e.target.value.toUpperCase())} name="password" style={styles.labels} />
                                </div>
                                </div>
                                <p className="uk-text-right uk-flex uk-flex-around">
                                    <button className="uk-button uk-button-default uk-modal-close" type="button">Cancelar</button>
                                    <button className="uk-button btn-success" onClick={()=> editUser() } type="button">Salvar</button>
                                </p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <Footer></Footer>

    
</div>
);
}
export default withRouter(AdminClient);