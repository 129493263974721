import React from 'react';
import Sicad from '../assests/sicad.png';
export default function Footer(){
    return (
      <div>
        <p className="uk-text-muted uk-text-center uk-text-small  uk-margin-small-top" style={{fontSize: '12px'}}> SICAD TI - Todos os direitos reservados ₢ 2019 - 2022   
            <a className="uk-padding-small" href="http://sicadti.com.br" style={{opacity: '0.7'}}>
                <img src={Sicad}  alt="SICAD TI" style={{width:'3%'}}/>
                </a>
            </p>
      </div>
    );
  }
