export const TOKEN_KEY = "@SicadCHW-Token";
export const GRUPO_KEY =  "@SicadCHW-GID";
export const USER_KEY =  "@SicadCHW-USER";
export const ID_KEY =  "@SicadCHW-USERID";
export const ID_OPR =  "@SicadCHW-OPRID";
export const MAIL_KEY =  "@SicadCHW-USERMAIL";
export const OPR_NAME =  "@SicadCHW-OPRNAME";
export const DEP_OPR = "@SicadCHW-DEPOPR";
export const TP_OPR = "@SicadCHW-TPOPR";

export const isAuthenticated2 = () =>  {

  if(!localStorage.getItem(TOKEN_KEY).trim()){

    return false

  }else return true

};

export const isAuthenticated = () =>  {

  if(!localStorage.getItem(TOKEN_KEY).trim() || !localStorage.getItem(MAIL_KEY).trim() ){     

      return false 

    }else

     return true

};
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getGrupo = () => localStorage.getItem(GRUPO_KEY);
export const getUser = () => localStorage.getItem(USER_KEY);
export const getId = () => localStorage.getItem(ID_KEY);
export const getOpr = () => localStorage.getItem(ID_OPR);
export const getMail = () => localStorage.getItem(MAIL_KEY);
export const getOName = () => localStorage.getItem(OPR_NAME);
export const getDep = () => localStorage.getItem(DEP_OPR);
export const getTpOpr = () => localStorage.getItem(TP_OPR);
export const isOpr = () => {if(localStorage.getItem(TP_OPR) === 'I'){return true}else{ return false} };

export const setDep = dep => {
  localStorage.setItem(DEP_OPR, dep)
}
export const setTpOpr = tp => {
  localStorage.setItem(TP_OPR, tp)
}
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const setGrupo = grpid => {
localStorage.setItem(GRUPO_KEY, grpid);
};
export const setOName = name => {
  localStorage.setItem(OPR_NAME, name);
  };
export const setOpr = opr => {
  localStorage.setItem(ID_OPR, opr);
  };
export const setMail = mail => {
  localStorage.setItem(MAIL_KEY, mail);
  };
export const setUser = user => {
  localStorage.setItem(USER_KEY, user);
  };
export const setId = id => {
    localStorage.setItem(ID_KEY, id);
    };
export const logout = () => {
  localStorage.clear()
};
