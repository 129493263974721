import React, { useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Back from '../../assests/bg_opr.jpg';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import MenuOp from '../../components/menuop';
import Footer from '../../components/footer';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import moment from 'moment';
import * as yup from 'yup'; 

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
let mails = yup.array().of(yup.string().email('Email Não Válido')).nullable().required('Email Requerido');
let schema = yup.object().shape({
    nick: yup.string('Nome de Usuário Requerido').required('Nome de Usuário Requerido').nullable(),
    phone: yup.string().matches(phoneRegExp, 'Número de telefone inválido')
                .nullable()
                .min(8,'Telefone Inválido')
                .required('Telefone Requerido')
            ,
    gruponame: yup.string('Grupo Requerido').required('Grupo Requerido').nullable(),
    password: yup.string('Senha Requerida').required('Senha Requerido').min(3, 'Senha muito curta').nullable()
  });

moment().locale('pt-br');
// loads the Icon plugin
UIkit.use(Icons);
let styles = {
    main:{
        backgroundImage: 'url('+Back+')',
        backgroundRepeat: 'no-repeat' , 
        minHeight:'100vh',
        backgroundSize: 'cover',
    }, 
    card: {
        borderRadius: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.29)',       
        
     },
     labels: {
         borderRadius: '10px',
         color: 'black',
     },
}
function DashOpr(){
    const groups = useSelector(state => state.groups)
    const [nick, setNick] = useState(null)
    const [grupoid, setGrupoid] = useState(null)
    const [gruponame, setGrupoName] = useState(null)
	const [groupsg, setGroupSg] = useState('')
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [phone, setPhone] = useState(null)
    const [button, setButton] = useState('Cadastrar');
    const dispatch = useDispatch();    
    
    useEffect(() => {         
        async function groups(){
            try{
                const selectGroups = await api.get('/groups')
                console.log(selectGroups.data)
                    dispatch({type: 'ADD_GRPEMP', dados: [...selectGroups.data]})
            } catch(err){ 
                        toast.error("Erro ao Buscar dados", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000, 
                        containerId: 'SaveUser'
                        })
                    }
                }
                groups();
            // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [])  

    function handleChangeGrupo(e) {
        setGrupoid(e.target.value)
        if (e.target.value > 0) {
            let name = document.getElementById(e.target.value).getAttribute('data')
            setGrupoName(name);
            !name ? setGroupSg('') : setGroupSg(name);
        } else {
            setGrupoName('Selecione o Grupo');
        }
    }
    async function handleSubmit(e) {        
        e.preventDefault();
        if (email !== null) {
            var emails = email.split(';');
            var restmail = []
            restmail = emails
        }
        
        let valid = await schema.validate({ gruponame, nick, phone, password })
            .catch(err => {
                toast.error(err.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    containerId: 'SaveUser'
                })
            })
        let valid2 = await mails.validate(restmail).catch(err => {
            toast.error(err.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                containerId: 'SaveUser'
            })
        })
        if (!grupoid) valid = false;
        if (!!valid && !!valid2) {
            setButton(<div uk-spinner="ratio: 1"></div>);
            try {
                const createUser =  await api.post('/opr/user', { gruponame, grupoid, nick, email, password, phone });
                if (createUser.status === 200) {
                    toast.warn(createUser.data.email, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        containerId: 'SaveUser'
                    });
                    toast.success(createUser.data.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                        containerId: 'SaveUser'
                    });
                    setButton('Cadastrar')
                }
                
            } catch (error) {
                toast.error('Erro ao Cadastrar Usuário', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    containerId: 'SaveUser'
                });
            }
				document.getElementById("Form").reset();
                setButton('Cadastrar')		
        }
    }
        return(
            <div className="uk-flex uk-flex-column uk-flex-1 uk-background-fixed uk-container-fluid uk-background-cover uk-background-blend-multiply" style={styles.main} uk-img='true'>
            <MenuOp></MenuOp>
            <div className="uk-container-fluid">            
              <div className="uk-text-center uk-flex uk-flex-center uk-flex-row" >
                    <div className="uk-width-1-2@m uk-padding-small uk-margin-large-top">
                        <div className="uk-card uk-card-default uk-background-default uk-text-emphasis uk-card-body" style={styles.card}>
                            <h1 className="uk-heading-divider uk-text-center uk-text-large">Cadastro de Usuário</h1>
                            <form id="Form" className="uk-form-horizontal uk-width-1 uk-margin-small">
                                <div className="uk-margin">                                
                                    <div className="uk-inline uk-width-1-1">
                                        <select className="uk-select" onChange={(e) => handleChangeGrupo(e)}  name="grupo" style={styles.labels} required>
                                        <option  value='Selecione o Grupo' data='Selecione o Grupo' >Selecione o Grupo</option>
                                            {groups.map( group => (
                                                <option  key={group.CAMPO1} value={group.CAMPO1} data={group.CAMPO4} id={group.CAMPO1}>{group.CAMPO2}</option>
                                            ))}                                           
                                        </select>
                                    </div>
                                </div>
								<div className="uk-margin">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon " href="#" ></span>
                                        <input className="uk-input" type="text" placeholder={`Sigla: ${groupsg}`} style={styles.labels} disabled/>

                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon " href="#" uk-icon="icon: user"></span>
                                        <input className="uk-input" maxLength="10" type="text" placeholder="Nome de Usuário" onChange={(e) => setNick(e.target.value)} name="nick" style={styles.labels} required="required"/>

                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon " href="#" uk-icon="icon: mail"></span>
                                            <input className="uk-input" type="email" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} name="email" style={styles.labels} required="required"/>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon " href="#" uk-icon="icon: receiver"></span>
                                            <input className="uk-input" type="tel" placeholder="Telefone (ex: 30398305 ou 5130398305)" onChange={(e) => setPhone(e.target.value)} name="phone" style={styles.labels} required="required"
                                                maxLength="14" pattern="\([0-9]{2}\) [0-9]{4,6}-[0-9]{3,4}$"
                                        />
                                    </div>
                                </div>
                                <div className="uk-margin">
                                <div className="uk-inline uk-width-1-1">
                                        <span className="uk-form-icon " href="#" uk-icon="icon: lock"></span>
                                        <input className="uk-input" maxLength="10" type="password" placeholder="Senha de Usuário" onChange={(e) => setPassword(e.target.value.toUpperCase())} name="password" style={styles.labels} required="required"/>
                                </div>
                                </div>
                            </form>
                            <button className="uk-button uk-button-primary uk-border-rounded" onClick={(e) => handleSubmit(e)}>{button}</button>
                            <ToastContainer enableMultiContainer containerId={'SaveUser'} position={toast.POSITION.TOP_RIGHT} />
                        </div>
                    </div>
                    
                </div>
            </div>
            <Footer></Footer>
    
            
        </div>
        );
}
export default withRouter(DashOpr);